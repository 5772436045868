import { BenchmarkCandle } from "../model/benchmarkCandle";

export const calculateTimeInMarket = (candles: BenchmarkCandle[]): number | null => {
  if (!candles || candles.length === 0) {
    return null;
  }

  const firstDate = new Date(candles[0].date);
  const lastDate = new Date(candles[candles.length - 1].date);
  
  return (lastDate.getFullYear() - firstDate.getFullYear()) * 12 + 
    (lastDate.getMonth() - firstDate.getMonth());
};