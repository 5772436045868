import { BenchmarkCandle } from "./model/benchmarkCandle";
import { correlationVsEquities } from "./raw/correlationVsEquities";
import { IndicatorResult } from "./model/indicatorResult";
import React from 'react';

export function getCorrelationVsEquitiesResult(fundCandles: BenchmarkCandle[], equityCandles: BenchmarkCandle[]): IndicatorResult {
  const value = correlationVsEquities(fundCandles, equityCandles);
  return {
    title: "Correlation vs Equities",
    value: value.toFixed(2),
    tooltip: "Correlation coefficient between fund and equity benchmark returns",
    rawValue: value
  };
}

interface Props {
  fundCandles: BenchmarkCandle[];
  equityCandles: BenchmarkCandle[];
}

export const CorrelationVsEquities: React.FC<Props> = ({ fundCandles, equityCandles }) => {
  const result = getCorrelationVsEquitiesResult(fundCandles, equityCandles);
  return <p>{result.value}</p>;
};