import { BlockchainNetworksTable } from "./BlockchainNetworksTable";
import DashboardComponent from "../../Components/DashboardComponent";
import {ErrorLoading, Spinner} from "../../Helpers/Helpers";
import React from "react";
import {useBlockchainNetworksList} from "../../query-hooks/wallet-query-hook/useWallet";

export function BlockchainNetworksDashboard() {
  const networks = useBlockchainNetworksList();
  return (
    <DashboardComponent title="Blockchain Networks Dashboard" subtitle="Blockchain Networks Dashboard" toCreate="/main/networks/create-network" buttonMessage="Create a Blockchain Network">
      {networks.isLoading && <Spinner />}
      {networks.isError && <ErrorLoading error={networks.error} />}
      {networks.isSuccess && <BlockchainNetworksTable items={networks.data.data.items!} />}
    </DashboardComponent>

  );
}
