import { BenchmarkCandle } from "../model/benchmarkCandle";
import { annualizedReturn } from "../raw/annualizedReturn";
import { negativeReturnsVolatility } from "./negativeReturnsVolatility";

/**
 * Calculates the Sortino ratio for a series of benchmark values
 * @param weightedSums Array of benchmark candles with normalized values
 * @param riskFreeRate Annual risk-free rate (default: 0)
 * @returns Sortino ratio or Infinity/0 for special cases
 */
export function sortinoRatio(weightedSums: BenchmarkCandle[], riskFreeRate: number = 0) {
    if (weightedSums.length < 2) {
        return 0;
    }

    if (riskFreeRate < 0) {
        throw new Error("Risk-free rate cannot be negative");
    }

    const excessReturn = annualizedReturn(weightedSums) - riskFreeRate;
    const downsideVolatility = negativeReturnsVolatility(weightedSums);

    if (downsideVolatility === 0) {
        return excessReturn > 0 ? Infinity : 0;
    }

    return excessReturn / downsideVolatility;
}