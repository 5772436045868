import { BenchmarkCandle } from "../model/benchmarkCandle";

/**
 * Calculates the maximum drawdown from peak to trough across the entire period
 * @param weightedSums Array of benchmark candles with normalized values
 * @returns Maximum drawdown as a positive percentage (0 to 1)
 */
export function maxDrawdown(weightedSums: BenchmarkCandle[]): number {
    if (weightedSums.length < 2) {
        return 0;
    }

    let maxDrawdown = 0;
    let peak = weightedSums[0].normalizedValue;

    for (let i = 1; i < weightedSums.length; i++) {
        const current = weightedSums[i].normalizedValue;
        
        // Update the maximum drawdown if we find a larger drawdown
        const drawdown = (peak - current) / peak;
        maxDrawdown = Math.max(maxDrawdown, drawdown);

        // Update peak if we find a new high
        peak = Math.max(peak, current);
    }

    return maxDrawdown;
}
