import axios from "axios";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { API_URL } from "../../settings";
import Swal from "sweetalert2";
import { simpleSuccessfulMessage } from "../../Helpers/Helpers";

// Function to get a fund transfers by its Id
const fetchTransfers = (fundId, bookingPeriod, selectedFundClassId, page) => axios.get(`${API_URL}/funds/${selectedFundClassId ? selectedFundClassId : fundId}/transfers?bookingPeriod=${bookingPeriod}`, { params: { page: page }}).then(res => res.data)
export function useTransfers(fundId, bookingPeriod, selectedFundClassId, page) {
  return useQuery([ "transfers", fundId, bookingPeriod, selectedFundClassId, page ], () => fetchTransfers(fundId, bookingPeriod, selectedFundClassId, page) );
}

// Function to get detail of one transfer by its ID
const fetchTransfer = transferId => axios.get(`${API_URL}/transfers/${transferId}`).then(response => response.data);
export function useTransfer(transferId) {
  return useQuery(["transfer", transferId], () => fetchTransfer(transferId), {enabled: transferId !== undefined});
}

// Function to fetch all Transfers Reservations
const getReservations = fundId => axios.get(`${API_URL}/funds/${fundId}/transfers/reservations`).then(res => res.data)
export const useGetReservations = fundId => useQuery(["reservations", fundId], () => getReservations(fundId))  

// Function to fetch all Transfers Explorers
const getExplorers = data =>  axios.get(`${API_URL}/transfers/explorer/${data.walletAddress}?startTime=${data.startTime}&endTime=${data.endTime}`).then(res => res.data)
export const useGetExplorers = data => useQuery(["explorers", data], () => getExplorers(data), { enabled: data?.walletAddress !== undefined || data?.startTime !== undefined || data?.endTime !== undefined })  

// MUTATIONS

//Function to post a Transfer
const postTransfer = (transfer) => axios.post(`${API_URL}/transfers`, transfer).then(response => response.data);

export function useCreateTransfer() {
  const queryClient = useQueryClient()
  return useMutation(transfer => postTransfer(transfer), 
  { onSuccess: async () => {
      queryClient.invalidateQueries("transfers")
      queryClient.invalidateQueries("fundHoldings")
      queryClient.invalidateQueries("fundHoldingsCompared")
      simpleSuccessfulMessage("Transfer created succesfully")
    } 
  });
}


// Function to edit Transfer
const editTransfer = async (transferId, newTransfer) => await axios.put(`${API_URL}/transfers/${transferId}`, newTransfer).then(response => response.data);
export function useEditTransfer() {
  const queryClient = useQueryClient()
  return useMutation(({ transferId, newTransfer }) => editTransfer(transferId, newTransfer),
  { onSuccess: async () => {
    queryClient.invalidateQueries("transfers")
    simpleSuccessfulMessage("Transfer edited succesfully")
  } 
});
}


// Function to get transfer 
export const getTransferDate = timestamp => {
  const date = new Date(timestamp);
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate()
  const year = date.getFullYear()
  return {day, month, year}
}

// Function to edit Transfer
const getTransfersInflow = fundId => axios.get(`${API_URL}/funds/${fundId}/transfers/inflow`).then(res => res.data)
export const useGetTransfersInflow = fundId => useQuery(["transfersInflow", fundId], () => getTransfersInflow(fundId))  

// MUTATION

//Function to delete Transfer
const deleteTransfer = transferId => axios.delete(`${API_URL}/transfers/${transferId}`).then(response => response.data);
export function useDeleteTransfer() {
  const queryClient = useQueryClient()
  return useMutation(({ transferId }) => deleteTransfer(transferId), 
    { onSuccess: async () => queryClient.invalidateQueries("transfers") });
}

// Message object inserted in delete message
const deleteMessageTransfer = {
  title: "Are you sure you want to delete this transfer?", 
  text: "You won't be able to revert this!", 
  icon: "warning", 
  showCancelButton: true, 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Yes, delete it!"
}

export const confirmationAlertDeleteTransfer = (event, transferId, mutation) => {
  event.preventDefault();
  Swal.fire(deleteMessageTransfer).then(result => result.isConfirmed && mutation.mutate({ transferId: transferId }) );
};


// Function to correct balance difference in holding edit mode. It creates a transfer with predefined conditions according to the holding data
const correctBalanceTransfer = holding => {
  const transfer = { 
    dateTime: new Date(),
    transactionType: holding.endBalance < 0 ? "Profit" : "Loss",
    fromHoldingId: holding.id, 
    feeHoldingId: holding.id, 
    transactionId: "-",
    fromAddress: "-",
    toAddress: "-",
    transferAmount: Math.abs(holding.endBalance),
    transferFee: 0,
    reference: "Clear Out Balance"
  }
  axios.post(`${API_URL}/transfers`, transfer).then(response => response.data);
} 

export function useCorrectBalanceTransfer() {
  const queryClient = useQueryClient()
  return useMutation(holding => correctBalanceTransfer(holding), 
  { onSuccess: async () => queryClient.invalidateQueries("transfers") });
}

//Function to post a Transfer with Reservations
const postTransferReservations = (fundId, transfer) => axios.post(`${API_URL}/transfers`, transfer).then(res => res.data);
export const useCreateTransferReservations = () => {
  const queryClient = useQueryClient()
  return useMutation(({ fundId, transfer}) => postTransferReservations(fundId, transfer), { 
    onSuccess: async () => {
      queryClient.invalidateQueries("transfers")
      simpleSuccessfulMessage("Transfer created succesfully")
    } 
  });
}

//Function to post a Transfer explorers import
const postTransferExplorerImport = (params, transfer) => axios.post(`${API_URL}/transfers/explorer/${params.walletAddress}/import?startTime=${params.startTime}&endTime=${params.endTime}`, transfer).then(res => res.data);
export const useCreateTransferExplorerImport = () => {
  const queryClient = useQueryClient()
  return useMutation(({ params, transfer}) => postTransferExplorerImport(params, transfer), { 
    onSuccess: async () => {
      queryClient.invalidateQueries("explorers")
      simpleSuccessfulMessage("Transfer imported succesfully")
    } 
  });
}