import { BenchmarkCandle } from "./model/benchmarkCandle";
import { calculateTimeInMarket } from "./raw/timeInMarket";

export const getTimeInMarketResult = (candles: BenchmarkCandle[]) => {
  const diffInMonths = calculateTimeInMarket(candles);
  
  if (diffInMonths === null) {
    return {
      title: "Time in Market",
      value: "N/A",
      tooltip: "Duration since first investment"
    };
  }

  const years = Math.floor(diffInMonths / 12);
  const months = diffInMonths % 12;
  
  let value = "";
  if (years > 0) {
    value += `${years}y `;
  }
  if (months > 0 || years === 0) {
    value += `${months}m`;
  }

  return {
    title: "Time in Market",
    value: value.trim(),
    tooltip: "Duration since first investment",
    rawValue: diffInMonths
  };
};