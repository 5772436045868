import { BenchmarkCandle } from "../model/benchmarkCandle";

export function cagr(weightedSums: BenchmarkCandle[]) {
    if (weightedSums.length < 2) {
        return 0;
    }

    const first = weightedSums[0];
    const last = weightedSums[weightedSums.length - 1];

    // Calculate time difference in years based on months. We can do this, because
    // we are certain that the data is sorted chronologically
    const [startYear, startMonth] = first.date.split('-').map(Number);
    const [endYear, endMonth] = last.date.split('-').map(Number);
    const monthsDiff = (endYear - startYear) * 12 + (endMonth - startMonth);
    const yearsDiff = monthsDiff / 12;

    if (yearsDiff === 0) {
        return 0;
    }

    // CAGR formula: (final/initial)^(1/years) - 1
    const totalGrowthFactor = last.normalizedValue / first.normalizedValue;
    return Math.pow(totalGrowthFactor, 1/yearsDiff) - 1;
}
