import { BenchmarkCandle } from "./model/benchmarkCandle";
import { skewness } from "./raw/skewness";
import { IndicatorResult } from "./model/indicatorResult";
import React from 'react';

export function getSkewnessResult(input: BenchmarkCandle[]): IndicatorResult {
  const value = skewness(input);
  return {
    title: "Skewness",
    value: value.toFixed(2),
    tooltip: "Measure of return distribution asymmetry",
    rawValue: value
  };
}

interface Props {
  input: BenchmarkCandle[];
}

export const Skewness: React.FC<Props> = ({ input }) => {
  const result = getSkewnessResult(input);
  return <p>{result.value}</p>;
};