import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import HoldingsTable from '../HoldingsTable/HoldingsTable';
import { ReconciliationTable }  from '../ReconciliationTable/ReconciliationTable';
import { useBalanceReconciliation } from '../../../../query-hooks/balance-reconciliation-hooks/useBalanceReconciliation';
import { FaExclamationTriangle } from 'react-icons/fa'; // Import warning icon

interface TabsWrapperProps {
  reportingCurrencyCode: string;
  fundId: string;
  bookingPeriod: string;
  selectedFundClassId: string;
}

const TabsWrapper = ({ reportingCurrencyCode, fundId, bookingPeriod, selectedFundClassId }: TabsWrapperProps) => {
  const [activeTab, setActiveTab] = useState<any>('holdings');

  const {data: reconciliationData} = useBalanceReconciliation(selectedFundClassId || fundId);

  const dataSum = reconciliationData?.data.map(x => {
    const bankBalance = x.bankAccountBalances?.map(b => b.balance!).reduce((sum, item) => sum! + item!, 0) || 0;
    const walletBalance = x.walletBalances?.map(b => b.balance!).reduce((sum, item) => sum! + item!, 0) || 0;
    const holdingBalance = x.holding?.endBalance || 0;
    return (bankBalance + walletBalance - holdingBalance) * (x.holding?.endUSDPrice ?? 0);
  }).reduce((sum, item) => sum! + Math.abs(item!), 0) || 0;

  const getTabStyles = (key: string|null) => {
    return {
      style: {
        color: '#6D9E93',
        backgroundColor: activeTab === key ? '#5D8E8322' : '#FFFFFF00'
      }
    };
  }

  return (
    <div>
      <Tabs
        fill
        id="holdings-tabs"
        activeKey={activeTab}
        onSelect={(key) => setActiveTab(key)}
        className="mb-3 mt-0"
      >
        <Tab 
          eventKey="holdings" 
          title="Holdings" 
          tabAttrs={getTabStyles('holdings')}
        >
          <div className={'px-4'}>
            {activeTab == 'holdings' && <HoldingsTable
              reportingCurrencyCode={reportingCurrencyCode}
              fundId={fundId}
              bookingPeriod={bookingPeriod}
              selectedFundClassId={selectedFundClassId}
            />}
          </div>
        </Tab>
        <Tab 
          eventKey="reconciliation" 
          title={
            <span>
              Reconcile Balances
              { dataSum > 100 && (
                <FaExclamationTriangle style={{ color: 'red', marginLeft: '8px' }} />
              )}
            </span>
          } 
          tabAttrs={getTabStyles('reconciliation')}
        >
          <div className={'px-4'}>
            <ReconciliationTable fundId={selectedFundClassId || fundId} />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default TabsWrapper;
