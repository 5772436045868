import React, { useState } from "react";
import CumulativeReturn from "./Calculations/CumulativeReturn";
import SharpeRatioComponent from "./Calculations/SharpeRatioComponent";
import BenchmarkInvestment from "./Calculations/BenchmarkInvestment";
import styles from "./BenchmarkDashboard.module.scss";
import { Row, Col } from "react-bootstrap";
import { quotesStringHandler } from "../../Helpers/Helpers";
import { BenchmarkCandle } from "./Calculations/model/benchmarkCandle";
import { sumWeightedValuations } from "./Calculations/sumWeightedValuations";
import { YtD } from "./Calculations/YtD";
import { YoY } from "./Calculations/YoY";
import { CAGR } from "./Calculations/CAGR";
import { AnnualizedReturn } from "./Calculations/AnnualizedReturn";
import { AnnualizedVolatility } from "./Calculations/AnnualizedVolatility";
import { SortinoRatio } from "./Calculations/SortinoRatio";
import { MaxMonthlyDrawdown } from "./Calculations/MaxMonthlyDrawdown";
import { MaxDrawdown } from "./Calculations/MaxDrawdown";
import { Kurtosis } from "./Calculations/Kurtosis";
import { Skewness } from "./Calculations/Skewness";

/*
key can be fundid or benchmarkid, these are put into the same object
data = {
  [key: string]: {
    change:0,
    date: "2020-07-31T16:00:00+02:00",
    normalizedValue: 1,
    price: 306.8741
  }[]
}

quotesPercentages = {
  id: string,
  name: string,
  percentage: number,
  symbol: string
}[]
*/

const BenchmarkTable = ({
  data,
  investment,
  fund,
  quotesPercentages,
  handleGraphdataChange,
  tableRef,
  benchmarkData,
  transformedFundName,
  showAdvancedAnalysis,
}) => {
  // Default Fund percentages for table
  const [fundPercentagesState, setFundPercentagesState] = useState([
    { id: 0, percentage: 1 },
    {
      id: 1,
      percentage: 3,
    },
    { id: 2, percentage: 5 },
  ]);
  const [zeroPercentSharpeRatio, setZeroPercentSharpeRatio] = useState(0);

  // Raw data without hodl allocation in portfolio
  const zeroPercentageRawData = [];
  for (let i = 0; i < quotesPercentages.length; i++) {
    const bm = quotesPercentages[i];
    const quoteCandles = data[quotesPercentages[i].id];
    const mappedCandles = quoteCandles.map((y) => new BenchmarkCandle(y));
    zeroPercentageRawData.push({
      name: bm.name,
      id: bm.id,
      candles: mappedCandles,
      weight: bm.percentage,
    });
  }

  const zeroPercentageCombinedData = sumWeightedValuations(
    zeroPercentageRawData
  );
  // end Raw data without hodl allocation in portfolio

  const USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return (
    <React.Fragment >
      <Row ref={tableRef}>
        <Col className={styles.tableContainer} >
          <div className={styles.col1}>
            <p className={styles.tableContent}>Total Return % (Cumulative)</p>
            <p className={styles.tableContent}>Sharpe Ratio</p>
            <p className={styles.tableContent}>Change in Sharpe Ratio</p>
            <p className={styles.tableContent}>
              Profit on a ${investment / 1000000} mln investment
            </p>
            {showAdvancedAnalysis && (
              <>
                <hr />
                <p className={styles.tableContent}>Year to date</p>
                <p className={styles.tableContent}>Year over year</p>
                <p className={styles.tableContent}>CAGR</p>
                <p className={styles.tableContent}>Annualized returns</p>
                <p className={styles.tableContent}>Annualized volatility</p>
                <p className={styles.tableContent}>Sortino ratio</p>
                <p className={styles.tableContent}>Maximum monthly drawdown</p>
                <p className={styles.tableContent}>Maximum drawdown</p>
                <p className={styles.tableContent}>Kurtosis</p>
                <p className={styles.tableContent}>Skewness</p>
              </>
            )}
          </div>
        </Col>

        <Col xxl={2}>
          <h5 className={styles.tableTitle}>
            {quotesStringHandler(quotesPercentages)}
          </h5>
          <div className={styles.col2}>
            <div className={styles.tableContent}>
              <CumulativeReturn
                data={data}
                investment={investment}
                fund={fund}
                fundPercentage={0}
                quotesPercentages={quotesPercentages}
                handleGraphdataChange={handleGraphdataChange}
              />
            </div>
            <div className={styles.tableContent}>
              <SharpeRatioComponent
                data={data}
                investment={investment}
                fund={fund}
                fundPercentage={0}
                quotesPercentages={quotesPercentages}
                benchmarkData={benchmarkData}
                setZeroPercentSharpeRatio={setZeroPercentSharpeRatio}
              />
            </div>
            <div className={styles.tableContent}>
              <BenchmarkInvestment
                data={data}
                investment={investment}
                fund={fund}
                fundPercentage={0}
                quotesPercentages={quotesPercentages}
              />
            </div>
            {showAdvancedAnalysis && (
              <>
                <hr />
                <div className={styles.tableContent}>
                  <YtD input={zeroPercentageCombinedData} />
                </div>
                <div className={styles.tableContent}>
                  <YoY input={zeroPercentageCombinedData} />
                </div>
                <div className={styles.tableContent}>
                  <CAGR input={zeroPercentageCombinedData} />
                </div>
                <div className={styles.tableContent}>
                  <AnnualizedReturn input={zeroPercentageCombinedData} />
                </div>
                <div className={styles.tableContent}>
                  <AnnualizedVolatility input={zeroPercentageCombinedData} />
                </div>
                <div className={styles.tableContent}>
                  <SortinoRatio input={zeroPercentageCombinedData} />
                </div>
                <div className={styles.tableContent}>
                  <MaxMonthlyDrawdown input={zeroPercentageCombinedData} />
                </div>
                <div className={styles.tableContent}>
                  <MaxDrawdown input={zeroPercentageCombinedData} />
                </div>
                <div className={styles.tableContent}>
                  <Kurtosis input={zeroPercentageCombinedData} />
                </div>
                <div className={styles.tableContent}>
                  <Skewness input={zeroPercentageCombinedData} />
                </div>
              </>
            )}
          </div>
        </Col>
        {fundPercentagesState.map((fundPercentageState) => {
          // note that the sumWeightedValuations function doesn't need the percentages to add
          // up to 100, it will look at the weights and calculate the percentage from the total
          // of all weights. That's why we need to adjust the weights for the stocks/bonds
          // here.
          var newWeightForBenchmark =
            100 / (100 + fundPercentageState.percentage);
          var withFundCombinedData = sumWeightedValuations([
            ...zeroPercentageRawData.map((x) => ({
              ...x,
              weight: x.weight * newWeightForBenchmark,
            })),
            {
              candles: data[fund.id].map((y) => new BenchmarkCandle(y)),
              id: fund.id,
              name: fund.name,
              weight: fundPercentageState.percentage,
            },
          ]);

          return (
            <Col xxl={2} key={fundPercentageState.id}>
              <h5 className={styles.tableTitle}>
                +{fundPercentageState.percentage}% {transformedFundName}
              </h5>
              <div className={styles[`col${fundPercentageState.id + 3}`]}>
                <div className={styles.tableContent}>
                  <CumulativeReturn
                    data={data}
                    investment={investment}
                    fund={fund}
                    fundPercentage={fundPercentageState.percentage}
                    quotesPercentages={quotesPercentages}
                    handleGraphdataChange={handleGraphdataChange}
                  />
                </div>
                <div className={styles.tableContent}>
                  <SharpeRatioComponent
                    data={data}
                    investment={investment}
                    fund={fund}
                    fundPercentage={fundPercentageState.percentage}
                    quotesPercentages={quotesPercentages}
                    benchmarkData={benchmarkData}
                    zeroPercentSharpeRatio={zeroPercentSharpeRatio}
                  />
                </div>
                <div className={styles.tableContent}>
                  <BenchmarkInvestment
                    data={data}
                    investment={investment}
                    fund={fund}
                    fundPercentage={fundPercentageState.percentage}
                    quotesPercentages={quotesPercentages}
                  />
                </div>
                {showAdvancedAnalysis && (
                  <>
                    <hr />
                    <div className={styles.tableContent}>
                      <YtD input={withFundCombinedData} />
                    </div>
                    <div className={styles.tableContent}>
                      <YoY input={withFundCombinedData} />
                    </div>
                    <div className={styles.tableContent}>
                      <CAGR input={withFundCombinedData} />
                    </div>
                    <div className={styles.tableContent}>
                      <AnnualizedReturn input={withFundCombinedData} />
                    </div>
                    <div className={styles.tableContent}>
                      <AnnualizedVolatility input={withFundCombinedData} />
                    </div>
                    <div className={styles.tableContent}>
                      <SortinoRatio input={withFundCombinedData} />
                    </div>
                    <div className={styles.tableContent}>
                      <MaxMonthlyDrawdown input={withFundCombinedData} />
                    </div>
                    <div className={styles.tableContent}>
                      <MaxDrawdown input={withFundCombinedData} />
                    </div>
                    <div className={styles.tableContent}>
                      <Kurtosis input={withFundCombinedData} />
                    </div>
                    <div className={styles.tableContent}>
                      <Skewness input={withFundCombinedData} />
                    </div>
                  </>
                )}
              </div>
            </Col>
          );
        })}
      </Row>
    </React.Fragment>
  );
};
export default BenchmarkTable;
