// Function to fetch Coinmarketcap API to get crypto data
import {useQuery, UseQueryResult} from "react-query";
import axios, { AxiosResponse } from "axios";
import {API_URL} from "../../settings";

const fetchCoinGeckoCoins = () =>
    axios
        .get("https://api.coingecko.com/api/v3/coins/list", {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: false
        })
        .then((response) => response.data);

export function useCoinGeckoCoins() {
    return useQuery("coinGeckoCoins", fetchCoinGeckoCoins);
}

const fetchExchanges = (source:string) =>
    axios
        .get( API_URL + "/marketAPI/exchanges/" + source, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: false
        })
        .then((response) => response.data);

export function useRemoteExchanges(source:string) {
    return useQuery("remoteExchanges." + source, ()=> fetchExchanges(source));
}

const blockchainIds = async (source:string) => await axios.get(`${API_URL}/marketAPI/blockchains/${source}`).then(res => res.data);

export const useBlockchainIds: (source: string)=>UseQueryResult<string[]>
= (source) => useQuery(["blockchainIds", source], () => blockchainIds(source), { enabled: source !== undefined });
