import { BenchmarkCandle } from "../model/benchmarkCandle";

export function ytdReturn(weightedSums: BenchmarkCandle[]){
    const last = weightedSums[weightedSums.length - 1];
    const year = last.date.split("-")[0];

    const firstIndex = weightedSums.findIndex((x) => x.date.startsWith(year));
    if(firstIndex == -1 || firstIndex == 0){
        return 0;
    }

    // dec 1st
    const first = weightedSums[firstIndex - 1];
    return (last.normalizedValue - first.normalizedValue) / first.normalizedValue;
}