import { BenchmarkCandle, WeightedBenchmark } from "../model/benchmarkCandle";
import { sumWeightedValuations } from "../sumWeightedValuations";

export function returnSinceStartOfPeriod(weightedSums: BenchmarkCandle[]) {
    if (weightedSums.length < 2) {
        return 0;
    }

    const first = weightedSums[0];
    const last = weightedSums[weightedSums.length - 1];

    return (last.normalizedValue - first.normalizedValue) / first.normalizedValue;
}
