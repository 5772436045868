import { BenchmarkCandle } from "./model/benchmarkCandle";
import { sortinoRatio } from "./raw/sortinoRatio";
import { IndicatorResult } from "./model/indicatorResult";
import React from 'react';

export function getSortinoRatioResult(input: BenchmarkCandle[]): IndicatorResult {
  const value = sortinoRatio(input);
  return {
    title: "Sortino Ratio",
    value: value.toFixed(2),
    tooltip: "Risk-adjusted return focusing on downside volatility",
    rawValue: value
  };
}

interface Props {
  input: BenchmarkCandle[];
}

export const SortinoRatio: React.FC<Props> = ({ input }) => {
  const result = getSortinoRatioResult(input);
  return <p>{result.value}</p>;
};