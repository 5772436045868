import { BenchmarkCandle } from "../model/benchmarkCandle";

/**
 * Calculates the maximum drawdown between any two consecutive months
 * @param weightedSums Array of benchmark candles with normalized values
 * @returns Maximum monthly drawdown as a positive percentage (0 to 1)
 */
export function maxMonthlyDrawdown(weightedSums: BenchmarkCandle[]): number {
    if (weightedSums.length < 2) {
        return 0;
    }

    let maxDrawdown = 0;

    for (let i = 1; i < weightedSums.length; i++) {
        const previous = weightedSums[i - 1].normalizedValue;
        const current = weightedSums[i].normalizedValue;
        const drawdown = (previous - current) / previous;
        maxDrawdown = Math.max(maxDrawdown, drawdown);
    }

    return maxDrawdown;
}
