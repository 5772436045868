import React from "react";
import TabsWrapper from "./TabsWrapper/TabsWrapper";

const FundHoldingDashboardCard = ({ reportingCurrencyCode, fundId, bookingPeriod, selectedFundClassId }) => {
  return (
    <TabsWrapper
      reportingCurrencyCode={reportingCurrencyCode}
      fundId={fundId}
      bookingPeriod={bookingPeriod}
      selectedFundClassId={selectedFundClassId}
    />
  );
};

export default FundHoldingDashboardCard;
