import { BenchmarkCandle } from "./model/benchmarkCandle";
import { annualizedVolatility } from "./raw/annualizedVolatility";
import { IndicatorResult } from "./model/indicatorResult";
import React from 'react';

export function getAnnualizedVolatilityResult(input: BenchmarkCandle[]): IndicatorResult {
  const value = annualizedVolatility(input);
  return {
    title: "Annualized Volatility",
    value: `${(value * 100).toFixed(2)}%`,
    tooltip: "Annual standard deviation of returns, a measure of risk",
    rawValue: value
  };
}

interface Props {
  input: BenchmarkCandle[];
}

export const AnnualizedVolatility: React.FC<Props> = ({ input }) => {
  const result = getAnnualizedVolatilityResult(input);
  return <p>{result.value}</p>;
};