import axios from "axios";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { API_URL } from "../../settings";
import Swal from "sweetalert2";
import { AlertFunction } from "../../Helpers/Helpers";

// Function to get all banks
const fetchBanks = () => axios.get(`${API_URL}/banks`).then(response => response.data);
export function useBanks() {
  return useQuery("banks", fetchBanks);
}

// Function to get detail of one bank
const fetchBank = (bankId) => axios.get(`${API_URL}/banks/${bankId}`).then((response) => response.data);

export function useBank(bankId) {
  return useQuery(["bank", bankId], () => fetchBank(bankId), {enabled: bankId !== undefined});
}

//Function to get All BankAccount's Balances
const fetchBankAccountBalances = bankAccountId => axios.get(`${API_URL}/bankaccounts/${bankAccountId}/balances`).then(response => response.data)
export function useBankAccountBalances(bankAccountId) {
  return useQuery(["bankAccountBalances", bankAccountId], () => fetchBankAccountBalances(bankAccountId) )
}

// Function to get detail of one bank account balance
const fetchBankAccountBalance = (bankAccountId, bankBalanceId) => axios.get(`${API_URL}/bankaccounts/${bankAccountId}/balances/${bankBalanceId}`).then(response => response.data);
export function useBankAccountBalance(bankAccountId, bankBalanceId) {
  return useQuery(["bankAccountBalance", bankAccountId, bankBalanceId], () => fetchBankAccountBalance(bankAccountId, bankBalanceId), {enabled: bankBalanceId !== undefined});
}

// Function to get one Bank's Accounts
const fetchBankAccounts = bankId => axios.get(`${API_URL}/banks/${bankId}/accounts`).then(response => response.data)
export function useBankAccounts(bankId) {
  return useQuery(["bankAccounts", bankId], () => fetchBankAccounts(bankId), {enabled: bankId !== undefined && bankId !== ''})
}

// Function to get detail of one bank account 
const fetchBankAccount = bankAccountId => axios.get(`${API_URL}/bankaccounts/${bankAccountId}`).then(response => response.data);
export function useBankAccount(bankAccountId) {
  return useQuery(["bankAccount", bankAccountId], () => fetchBankAccount(bankAccountId), {enabled: bankAccountId !== undefined});
}

// MUTATIONS

// BANK MUTATIONS
//Function to post Bank
const postBank = (bank) => axios.post(`${API_URL}/banks`, bank).then(response => response.data);

export function useCreateBank() {
  const queryClient = useQueryClient()
  return useMutation(bank => postBank(bank),
  {
    onSuccess: () => {
      queryClient.invalidateQueries("banks")
    }
  }
  );
}

//Function to edit Bank
const editBank = (bank, bankId) => axios.put(`${API_URL}/banks/${bankId}`, bank).then(response => response.data);

export function useEditBank() {
  const queryClient = useQueryClient()
  return useMutation(({ bank, bankId }) => editBank(bank, bankId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("banks")
      }
    }
  );
}

//Function to delete Bank
const deleteBank = (id) => axios.delete(`${API_URL}/banks/${id}`).then(response => response.data);
export function useDeleteBank() {
  const queryClient = useQueryClient()
  return useMutation(({ id }) => deleteBank(id), 
    { onSuccess: async () => queryClient.invalidateQueries("banks"), onError: async error => AlertFunction(error)  } );
}
// Message object inserted in delete message
const deleteMessageObject = {
  title: "Are you sure you want to delete this?", 
  text: "You won't be able to revert this!", 
  icon: "warning", 
  showCancelButton: true, 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Yes, delete it!"
}

export const confirmationAlertDeleteBank = (event, identifier, mutation) => {
  event.preventDefault();
  Swal.fire(deleteMessageObject).then((result) => result.isConfirmed && mutation.mutate({ id: identifier }) );
};

// BANK ACCOUNT MUTATIONS
//Function to post a Fund Bank Account
const postFundBankAccount = async (bankAccount) => await axios.post(`${API_URL}/bankaccounts`, bankAccount).then((response) => response.data)
export function useCreateBankAccount() {
  const queryClient = useQueryClient();
  return useMutation(({ bankAccount }) => postFundBankAccount(bankAccount),
    { onSuccess: async () => queryClient.invalidateQueries("bankAccount"), onError: async error => AlertFunction(error)  }
  );
}

//Function to edit a Fund Bank Account
const editFundBankAccount = async (bankAccountEdit, bankAccountId) => await axios.put(`${API_URL}/bankaccounts/${bankAccountId}`, bankAccountEdit).then(response => response.data)
export function useEditBankAccount() {
  const queryClient = useQueryClient();
  return useMutation(({ bankAccountEdit, bankAccountId }) => editFundBankAccount(bankAccountEdit, bankAccountId),
    { onSuccess: async () =>  queryClient.invalidateQueries("bankAccount"), onError: async error => AlertFunction(error)  }
  );
}

// Function to delete bank Account
export const deleteBankAccount = (bankAccountId) => axios.delete(`${API_URL}/bankaccounts/${bankAccountId}`).then(response => response.data);
export function useDeleteBankAccount() {
  const queryClient = useQueryClient()
  return useMutation(({ bankAccountId }) => deleteBankAccount(bankAccountId), 
    { onSuccess: async () => {
      queryClient.invalidateQueries("bankAccounts")
      queryClient.invalidateQueries("fund")
    }, onError: async error => AlertFunction(error)  }
  )
}

export const confirmationAlertDeleteBankAccount = (event, bankAccountId, mutation) => {
  event.preventDefault();
  Swal.fire(deleteMessageObject).then((result) => result.isConfirmed && mutation.mutate({ bankAccountId: bankAccountId }) );
};


// BANK ACCOUNT BALANCES MUTATIONS
//Function to post Bank Account Balance
const postBankAccountBalance = async (bankAccountId, bankAccountBalance) => await axios
  .post(`${API_URL}/bankaccounts/${bankAccountId}/balances`, bankAccountBalance).then(response => response.data);

export function useCreateBankAccountBalance() {
  const queryClient = useQueryClient()
  return useMutation( ({bankAccountId, bankAccountBalance})=> postBankAccountBalance(bankAccountId, bankAccountBalance),
  { onSuccess: () => queryClient.invalidateQueries("bankAccountBalances"), onError: async error => AlertFunction(error) });
}


//Function to edit Bank
const editBankAccountBalance = async (bankAccountId, bankBalanceId, newBalance) => await 
axios.put(`${API_URL}/bankaccounts/${bankAccountId}/balances/${bankBalanceId}`, newBalance).then(response => response.data);
export function useEditBankAccountBalance() {
  const queryClient = useQueryClient()
  return useMutation(({ bankAccountId, bankBalanceId, newBalance }) => editBankAccountBalance(bankAccountId, bankBalanceId, newBalance),
  { onSuccess: () => queryClient.invalidateQueries("bankAccountBalances"), onError: async error => AlertFunction(error) });
}


//Function to delete Bank Account Balance
const deleteBankAccountBalance = (bankAccountId, bankBalanceId) => axios.delete(`${API_URL}/bankaccounts/${bankAccountId}/balances/${bankBalanceId}`).then(response => response.data);
export function useDeleteBankAccountBalance() {
  const queryClient = useQueryClient()
  return useMutation(({ bankAccountId, bankBalanceId }) => deleteBankAccountBalance(bankAccountId, bankBalanceId), 
    { onSuccess: async () => queryClient.invalidateQueries("bankAccountBalances"), onError: async error => AlertFunction(error)  } );
}
// Message object inserted in delete message
const deleteMessageBankAccountBalance = {
  title: "Are you sure you want to delete this balance?", 
  text: "You won't be able to revert this!", 
  icon: "warning", 
  showCancelButton: true, 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Yes, delete it!"
}

export const confirmationAlertDeleteBankAccountBalance = (event, bankAccountId, bankBalanceId, mutation) => {
  event.preventDefault();
  Swal.fire(deleteMessageBankAccountBalance).then((result) => result.isConfirmed && mutation.mutate({ bankAccountId: bankAccountId, bankBalanceId: bankBalanceId }) );
};