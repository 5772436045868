import { useCallback } from "react";
import { BenchmarkCandle, WeightedBenchmark } from "./model/benchmarkCandle";

interface BenchmarkGrouping {
  bookingPeriod: string;
  benchmarks: BenchmarkCandle;
}

/**
 * This function sums lists of different values. It takes a list of weighted
 * benchmarks, and return the sum of values multiplied by weight, divided
 * by total weight.
 *
 * So if we have the values of
 * 1. hodl actively managed, (weight 3),
 * 2. SPY (weight 60)
 * 3. BNDX (weight 40)
 *
 * We return a list of new values, which is calculated following the following formula:
 * total[i]
 *  = (
 *      hodl[i].normalized * 3
 *      + spy[i].normalized * 60
 *      + bndx[i].normalized * 40
 *    ) / (3 + 60 + 40)
 */
export function sumWeightedValuations(weightedBenchmarks: WeightedBenchmark[]) {
  var candlesLength = weightedBenchmarks.map((x) => x.candles.length);
  if (candlesLength.length == 0) {
    throw new Error("No candles found");
  }

  var firstLength = candlesLength[0];
  if (candlesLength.some((x) => x !== firstLength)) {
    throw new Error("Candles are not of the same length");
  }

  const groupedByBookingPeriod = [];
  for (let i = 0; i < weightedBenchmarks[0].candles.length; i++) {
    const date = weightedBenchmarks[0].candles[i].date;
    const benchmarks = weightedBenchmarks.map((x) => x.candles[i]);
    groupedByBookingPeriod.push({ date, benchmarks });
  }

  let totals: BenchmarkCandle[] = [];
  // for each time frame
  for (var i = 0; i < groupedByBookingPeriod.length; i++) {
    let grouping = groupedByBookingPeriod[i];
    let total = 0;
    let totalWeight = 0;
    // sum all values
    for (let j = 0; j < weightedBenchmarks.length; j++) {
      totalWeight += weightedBenchmarks[j].weight;
      total += weightedBenchmarks[j].weight * grouping.benchmarks[j].normalizedValue;
    }

    // and store the calculated value
    var previousValue =
      totals.length > 0 ? totals[totals.length - 1].normalizedValue : 1;
    var newValue = total / totalWeight;
    totals.push(
      new BenchmarkCandle({
        normalizedValue: newValue,
        change: newValue / previousValue - 1,
        date: grouping.date,
        price: newValue,
      })
    );
  }

  return totals;
}