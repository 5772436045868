import React, {useEffect, useState} from "react";
import { Table } from "react-bootstrap";
import styles from "./AllWallets.module.scss";
import { Link } from "react-router-dom";
import Pagination from "../../Components/Pagination";
import {
  useDeleteWallet,
  confirmationAlertDeleteWallet,
} from "../../query-hooks/wallet-query-hook/useWallet";
import { useAuth } from "../../Hooks/useAuth";
import { timestampFormatter } from "../../Helpers/Helpers";

const AllWallets = ({ items }) => {
  const { darkmode } = useAuth();
  const [filteredItems, setFilteredItems] = useState();
  const [currentItems, setCurrentItems] = useState();
  const [search, setSearch] = useState("");
  const getCurrentItems = (updatedItems) => setCurrentItems(updatedItems);
  const mutation = useDeleteWallet();
  const searchLower = search.toLowerCase();
  useEffect(() => {
    const filteredItems = items.filter(
      (x) =>
        x.address.toLowerCase().includes(searchLower) ||
        x.description?.toLowerCase().includes(searchLower) ||
        x.exchangeAccount?.name?.toLowerCase()?.includes(searchLower)
    );
    setFilteredItems(filteredItems);
  }, [search, items]);

  return (
    <>
      <div className={"w-100 d-flex flex-row"}>
        <input
          className={styles.input}
          style={{ maxWidth: "14rem" }}
          type="text"
          placeholder="Search Wallets"
          value={search}
          onChange={(evt) => setSearch(evt.target.value)}
        />
      </div>
      <br />
      <div>
        <Table responsive borderless={true}>
          <thead>
            <tr className={darkmode ? styles.theaderDark : styles.theader}>
              <th>Date</th>
              <th>Address</th>
              <th>Description</th>
              <th>Exchange Account</th>
            </tr>
          </thead>
          {currentItems &&
            currentItems.map((wallet) => (
              <tbody key={wallet.address}>
                <tr
                  className={`${
                    darkmode ? styles.tableStripsDark : styles.tableStrips
                  } align-middle `}
                >
                  <td>{timestampFormatter(wallet.timestamp)}</td>
                  <td>{wallet.address}</td>
                  <td>{wallet.description}</td>
                  <td>
                    {wallet.exchangeAccount && wallet.exchangeAccount.name}
                  </td>
                  <td>
                    {" "}
                    <Link
                      className={
                        darkmode ? styles.editButtonDark : styles.editButton
                      }
                      to={`/main/wallets/balances/${wallet.address}`}
                    >
                      Balances
                    </Link>{" "}
                  </td>
                  <td>
                    {" "}
                    <Link
                      className={styles.editButton}
                      to={`edit-wallet/${wallet.address}`}
                    >
                      Edit
                    </Link>{" "}
                  </td>
                  <td>
                    {" "}
                    <button
                      className={styles.deleteButton}
                      onClick={(e) =>
                        confirmationAlertDeleteWallet(
                          e,
                          wallet.address,
                          mutation
                        )
                      }
                    >
                      Delete
                    </button>{" "}
                  </td>
                </tr>
              </tbody>
            ))}
        </Table>
        <Pagination
          items={filteredItems}
          getCurrentItems={getCurrentItems}
          itemsPerPage={15}
        />
      </div>
    </>
  );
};
export default AllWallets;
