import React, { useState, useEffect } from 'react'
import TransferForm from './TransferForm';
import TransferFormEdit from './TransferFormEdit';
import { useCreateTransfer, useTransfer, useEditTransfer, useCreateTransferReservations, useCreateTransferExplorerImport, useGetTransfersInflow } from '../../query-hooks/transfer-query-hooks/useTransfer';
import { useFund } from '../../query-hooks/fund-query-hooks/useFund';
import { simpleErrorMessage } from '../../Helpers/Helpers';
import { useAuth } from '../../Hooks/useAuth';

const TransferFormMutation = ({ fundId, transferId, setOpenFormCreateModalObject, bookingPeriod, action }) => {

    // Import Explorer State
    const [ importTransferExplorer, setImportTransferExplorer ] = useState()
    const [ fundings, setFundings ] = useState()

    const fund = useFund(fundId)
    const createTransfer = useCreateTransfer()  
    const createTransferReservations = useCreateTransferReservations()  
    const createTransferExplorerImport = useCreateTransferExplorerImport()
    const editTransfer = useEditTransfer()
    const transfer = useTransfer(transferId)
    const transfersInflow = useGetTransfersInflow(fundId)
    const { setTransferFormProps } = useAuth()

    const onSubmit = data => {
        if (data.toHoldingId === '') {
            data.toHoldingId = null
        }
        if (transferId) {
            return editTransfer.mutate({ transferId: transferId, newTransfer: data })
        }  
        if (data.transactionType === "BookReservations") {
            return createTransferReservations.mutate({ fundId: fundId, transfer: data })
        } 
        if (importTransferExplorer) {
            if (!fundings) {
                return simpleErrorMessage("Please enter a fulfilled Funds Percentage")
            }
            return createTransferExplorerImport.mutate({ 
                params: importTransferExplorer.queryParams, 
                transfer: { 
                    transactionHash: data.transactionId, 
                    fundings: fundings, 
                    transactionType: data.transactionType, 
                    toHolding: data.toHolding,
                    exchangeRate: data.exchangeRate,
                    shares: data.shares, 
                    reference: data.reference
                } 
            })
        }
        setTransferFormProps({ transactionType: data.transactionType, toHoldingId: data.toHoldingId, dateTime: data.dateTime })
        return createTransfer.mutate(data)
    }

    useEffect(() => { createTransfer.isSuccess && setOpenFormCreateModalObject() }, [createTransfer] )
    useEffect(() => { editTransfer.isSuccess && setOpenFormCreateModalObject() }, [editTransfer] )
    useEffect(() => { createTransferReservations.isSuccess && setOpenFormCreateModalObject() }, [createTransferReservations] )
    useEffect(() => { createTransferExplorerImport.isSuccess && setOpenFormCreateModalObject() }, [createTransferExplorerImport] )

    return (
        <>
            {   !transferId &&
                    <TransferForm 
                        onSubmit={onSubmit} 
                        fundId={fundId} 
                        importTransferExplorer={importTransferExplorer} 
                        setImportTransferExplorer={setImportTransferExplorer} 
                        setFundings={setFundings}
                        transfersInflow={transfersInflow}
                        fundData={fund.isSuccess && fund.data}
                        bookingPeriod={bookingPeriod}
                        action={action}
                    />
            }
            {   transfer.isSuccess &&
                    <TransferFormEdit
                        onSubmit={onSubmit} 
                        fundId={fundId} 
                        data={transfer.data} 
                        bookingPeriod={bookingPeriod} 
                        transfersInflow={transfersInflow}
                        fundData={fund.isSuccess && fund.data} 
                        action={action} 
                    /> 
            }
        </>
    );
};
export default TransferFormMutation