import { BenchmarkCandle } from "./model/benchmarkCandle";
import { yoyReturn } from "./raw/yoyReturn";
import { IndicatorResult } from "./model/indicatorResult";
import React from 'react';

export function getYoyResult(input: BenchmarkCandle[]): IndicatorResult {
  const value = yoyReturn(input);
  return {
    title: "YoY Return",
    value: `${(value * 100).toFixed(2)}%`,
    tooltip: "Year-over-year return",
    rawValue: value
  };
}

interface Props {
  input: BenchmarkCandle[];
}

export const YoY: React.FC<Props> = ({ input }) => {
  const result = getYoyResult(input);
  return <p>{result.value}</p>;
};