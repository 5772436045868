import { BlockchainNetworkListViewModel } from "@hodl.nl/hodltradingdesk";
import { Col, Container, Row, Table } from "react-bootstrap";
import styles from "./BlockchainNetworks.module.scss";
import { useAuth } from "../../Hooks/useAuth";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  confirmationAlertDeleteBlockchainNetwork,
  useBlockchainNetworkDelete
} from "../../query-hooks/wallet-query-hook/useWallet";
import { blockchainNetworkFilters } from "../../constants/enums";

export function BlockchainNetworksTable({
  items,
}: {
  items: BlockchainNetworkListViewModel[];
}) {
  const { darkmode } = useAuth();
  const { register, watch } = useForm({
    defaultValues: { filterValue: "mainnet" },
  });

  const deleteMutation = useBlockchainNetworkDelete();

  const filterValue = watch("filterValue");
  const filteredNetworks = items.filter((network) => {
    return (
      filterValue == "all" || network.isTestNet == (filterValue.toLowerCase() == "testnet")
    );
  });

  return (
    <Container>
      <Row>
        <Col sm={10}></Col>
        <Col sm={2}>
          <select
            className={darkmode ? styles.selectInputDark : styles.selectInput}
            {...register("filterValue")}
          >
            {blockchainNetworkFilters.map((value) => (
              <option key={value} value={value.toLowerCase()}>
                {value}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Table
        responsive
        borderless={true}
        className={darkmode ? styles.tableStripsDark : styles.tableStrips}
      >
        <thead className={darkmode ? styles.theaderDark : styles.theader}>
          <tr>
            <th>Name</th>
            <th>Symbol</th>
            <th style={{maxWidth:'10rem'}}></th>
          </tr>
        </thead>
        <tbody>
          {filteredNetworks.map((network: BlockchainNetworkListViewModel) => (
            <tr key={network.id}>
              <td>{network.name}</td>
              <td>{network.currencySymbol}</td>
              <td colSpan={1}>
                <Link
                  className={styles.editButton}
                  to={`/main/networks/edit-network/${network.id}`}
                >
                  Edit
                </Link>
                <button
                  style={{margin: 0}}
                  className={styles.deleteButton}
                  onClick={(e)=>confirmationAlertDeleteBlockchainNetwork(e, network.id,  deleteMutation)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
