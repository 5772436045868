import { BenchmarkCandle } from "./model/benchmarkCandle";
import { annualizedReturn } from "./raw/annualizedReturn";
import { IndicatorResult } from "./model/indicatorResult";
import React from 'react';

export function getAnnualizedReturnResult(input: BenchmarkCandle[]): IndicatorResult {
  const value = annualizedReturn(input);
  return {
    title: "Annualized Return",
    value: `${(value * 100).toFixed(2)}%`,
    tooltip: "Average annual return, adjusted for the time period",
    rawValue: value
  };
}

interface Props {
  input: BenchmarkCandle[];
}

export const AnnualizedReturn: React.FC<Props> = ({ input }) => {
  const result = getAnnualizedReturnResult(input);
  return <p>{result.value}</p>;
};