import { BenchmarkCandle } from "./model/benchmarkCandle";
import { returnSinceStartOfPeriod } from "./raw/returnSinceStartOfPeriod";
import { IndicatorResult } from "./model/indicatorResult";
import React from 'react';

export function getReturnSinceStartOfPeriodResult(input: BenchmarkCandle[]): IndicatorResult {
  const value = returnSinceStartOfPeriod(input);
  return {
    title: "Return Since Start",
    value: `${(value * 100).toFixed(2)}%`,
    tooltip: "Total return since the start of the period",
    rawValue: value
  };
}

interface Props {
  input: BenchmarkCandle[];
}

export const ReturnSinceStartOfPeriod: React.FC<Props> = ({ input }) => {
  const result = getReturnSinceStartOfPeriodResult(input);
  return <p>{result.value}</p>;
};