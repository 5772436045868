import { BenchmarkCandle } from "../model/benchmarkCandle";

/**
 * Calculates the excess kurtosis of returns
 * @param weightedSums Array of benchmark candles with normalized values
 * @returns Excess kurtosis (0 represents normal distribution, >0 means heavy tails, <0 means light tails)
 */
export function kurtosis(weightedSums: BenchmarkCandle[]): number {
    if (weightedSums.length < 4) { // Need at least 4 points for meaningful kurtosis
        return 0;
    }

    // Calculate returns
    const returns = [];
    for (let i = 1; i < weightedSums.length; i++) {
        const previous = weightedSums[i - 1].normalizedValue;
        const current = weightedSums[i].normalizedValue;
        const return_ = (current - previous) / previous;
        returns.push(return_);
    }

    // Calculate mean
    const mean = returns.reduce((sum, r) => sum + r, 0) / returns.length;

    // Calculate variance and fourth moment
    let variance = 0;
    let fourthMoment = 0;

    returns.forEach(return_ => {
        const deviation = return_ - mean;
        const squaredDeviation = deviation * deviation;
        variance += squaredDeviation;
        fourthMoment += squaredDeviation * squaredDeviation;
    });

    variance /= returns.length;
    fourthMoment /= returns.length;

    // Calculate excess kurtosis
    // Formula: (m4 / σ^4) - 3, where m4 is fourth moment and σ is standard deviation
    const excessKurtosis = (fourthMoment / (variance * variance)) - 3;

    return excessKurtosis;
}
