import React, { useMemo, useEffect, useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { sumWeightedValuations } from "./Calculations/sumWeightedValuations";
import { BenchmarkCandle } from "./Calculations/model/benchmarkCandle";
import { getAnnualizedReturnResult } from "./Calculations/AnnualizedReturn";
import { getSharpeRatioResult } from "./Calculations/SharpeRatio";
import { getAnnualizedVolatilityResult } from "./Calculations/AnnualizedVolatility";
import { getMaxMonthlyDrawdownResult } from "./Calculations/MaxMonthlyDrawdown";
import { getMaxDrawdownResult } from "./Calculations/MaxDrawdown";
import { getCagrResult } from "./Calculations/CAGR";
import { getKurtosisResult } from "./Calculations/Kurtosis";
import { getSkewnessResult } from "./Calculations/Skewness";
import { getSortinoRatioResult } from "./Calculations/SortinoRatio";
import { getCorrelationVsEquitiesResult } from "./Calculations/CorrelationVsEquities";
import { getYoyResult } from "./Calculations/YoY";
import { getYtdResult } from "./Calculations/YtD";
import { getReturnSinceStartOfPeriodResult } from "./Calculations/ReturnSinceStartOfPeriod";
import { getValueAtStartResult } from "./Calculations/ValueAtStart";
import { getTimeInMarketResult } from "./Calculations/TimeInMarket";
import styles from "./BenchmarkDashboard.module.scss";
import { FiInfo } from "react-icons/fi";
import { IoIosDownload } from "react-icons/io";

// Helper function to convert candle data to BenchmarkCandles
const convertToBenchmarkCandles = (candleData) => {
  if (!candleData) return [];

  return candleData.map(
    (candle) =>
      new BenchmarkCandle({
        change: candle.change,
        date: candle.date,
        normalizedValue: candle.normalizedValue,
        price: candle.price,
      })
  );
};

const calculateIndicators = (benchmarkCandles, spyBenchmarkCandles) => {
  if (benchmarkCandles.length === 0) return [];

  const currentValue = {
    title: "Current Value",
    value: `$${benchmarkCandles[
      benchmarkCandles.length - 1
    ].price.toLocaleString()}`,
    tooltip: "Current value of the fund",
    rawValue: benchmarkCandles[benchmarkCandles.length - 1].price,
  };

  return [
    // Value Metrics
    getValueAtStartResult(benchmarkCandles),
    currentValue,
    getTimeInMarketResult(benchmarkCandles),

    // Performance Metrics
    getReturnSinceStartOfPeriodResult(benchmarkCandles),
    getYtdResult(benchmarkCandles),
    getYoyResult(benchmarkCandles),
    getAnnualizedReturnResult(benchmarkCandles),
    getCagrResult(benchmarkCandles),

    // Risk Metrics
    getAnnualizedVolatilityResult(benchmarkCandles),
    getMaxDrawdownResult(benchmarkCandles),
    getMaxMonthlyDrawdownResult(benchmarkCandles),
    getSharpeRatioResult(benchmarkCandles),
    getSortinoRatioResult(benchmarkCandles),

    // Statistical Measures
    getCorrelationVsEquitiesResult(benchmarkCandles, spyBenchmarkCandles),
    getKurtosisResult(benchmarkCandles),
    getSkewnessResult(benchmarkCandles),
  ];
};

const BenchmarkIndicatorTable = ({
  data,
  fund,
  quotes,
  benchmarkData,
  allReturns,
  transformedFundName,
  handleDownloadImage,
  handleDownloadSVG,
}) => {
  const indicatorsRef = useRef(null);
  const benchmarks = [
    { id: fund.id, name: transformedFundName },
    ...allReturns.chartQuotes.map((quote) => ({
      id: quote.id,
      name: quote.symbol,
    })),
  ];

  // Get SPY data
  const spyQuote = quotes.data.find((x) => x.symbol === "SPY");
  const spyData = spyQuote ? data[spyQuote.id] : null;
  const spyBenchmarkCandles = useMemo(() => {
    if (!spyData) return [];
    return convertToBenchmarkCandles(spyData);
  }, [spyData]);

  // Calculate indicators for all benchmarks
  const allBenchmarkIndicators = useMemo(() => {
    return benchmarks.map(benchmark => {
      const benchmarkCandles = convertToBenchmarkCandles(
        data[benchmark.id] ?? allReturns.returns[benchmark.id] ?? []
      );
      return {
        name: benchmark.name,
        indicators: calculateIndicators(benchmarkCandles, spyBenchmarkCandles)
      };
    }).filter(b => b.indicators.length > 0);
  }, [data, allReturns, benchmarks, spyBenchmarkCandles]);

  if (allBenchmarkIndicators.length === 0) return null;

  const rowColors = [
    "#D5E8E4", // More saturated tint of darkGreen (#0E3531)
    "#DBE9DE", // More saturated tint of forestGreen (#669771)
    "#DCE7E4", // More saturated tint of pineGreen (#6D9E93)
    "#E8E9DD", // More saturated tint of khaki (#CACCBD)
    "#F6F3EE", // sand (#F6F3EE)
    "#E4F7D1", // Light tint of neon (#A4F34F)
  ];

  return (
    <>
      <div className="d-flex w-100">
        <div>
          <div
            className={`${styles.dropdown}`}
            style={{ marginRight: "auto" }}
            title="Download Chart Image"
          >
            <IoIosDownload className={styles.downloadIcon} />
            <div className={styles.dropdownContent}>
              <p
                onClick={() => handleDownloadImage("indicators", indicatorsRef)}
              >
                Download as PNG
              </p>
              <p onClick={() => handleDownloadSVG("indicators", indicatorsRef)}>
                Download as SVG
              </p>
            </div>
          </div>
          <span
            className={styles.chartTitle}
            style={{ fontFamily: "LarkenMedium" }}
          >
            Fund Performance Indicators
          </span>
        </div>
      </div>
      <div className={styles.tableContainer} ref={indicatorsRef}>
        <div style={{ overflowX: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '0 2px' }}>
            <thead>
              <tr>
                <th style={{
                  padding: '12px 16px',
                  fontSize: '18px',
                  color: '#2d2d2d',
                  textAlign: 'left',
                  backgroundColor: '#f8f9fa',
                  borderBottom: '2px solid #e9ecef'
                }}>
                  Indicators
                </th>
                {allBenchmarkIndicators.map((benchmark) => (
                  <th key={benchmark.name} style={{
                    padding: '12px 16px',
                    fontSize: '18px',
                    color: '#2d2d2d',
                    textAlign: 'right',
                    backgroundColor: '#f8f9fa',
                    borderBottom: '2px solid #e9ecef'
                  }}>
                    {benchmark.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {allBenchmarkIndicators[0].indicators.map((_, indicatorIndex) => (
                <tr key={indicatorIndex}>
                  <td style={{
                    padding: '8px 16px',
                    backgroundColor: rowColors[indicatorIndex % rowColors.length],
                    borderRadius: '4px 0 0 4px'
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <span style={{ fontSize: '14px', color: '#4a4a4a' }}>
                        {allBenchmarkIndicators[0].indicators[indicatorIndex].title}
                      </span>
                      <FiInfo
                        data-html2canvas-ignore="true"
                        style={{ color: '#666', cursor: 'pointer', opacity: 0.6 }}
                        title={allBenchmarkIndicators[0].indicators[indicatorIndex].tooltip}
                        size={14}
                      />
                    </div>
                  </td>
                  {allBenchmarkIndicators.map((benchmark, benchmarkIndex) => (
                    <td key={benchmark.name} style={{
                      padding: '8px 16px',
                      backgroundColor: rowColors[indicatorIndex % rowColors.length],
                      borderRadius: benchmarkIndex === allBenchmarkIndicators.length - 1 ? '0 4px 4px 0' : '0',
                      textAlign: 'right'
                    }}>
                      <span style={{ fontSize: '14px', fontWeight: '600', color: '#2d2d2d' }}>
                        {benchmark.indicators[indicatorIndex].value}
                      </span>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default BenchmarkIndicatorTable;
