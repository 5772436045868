import React from "react";
import { Row, Col } from "react-bootstrap";
import { spyBndxStringHandler } from "../../Helpers/Helpers";
import styles from "./BenchmarkDashboard.module.scss";
import { IoIosDownload } from "react-icons/io";

const BenchmarkTableInputs = ({
  data,
  fund,
  investment,
  setInvestment,
  quotesPercentages,
  fulfilledPercentage,
  setQuotePercentages,
  showAdvancedAnalysis,
  setShowAdvancedAnalysis,
  tableRef,
  handleDownloadImage,
  handleDownloadSVG,
}) => {
  // Function to reassign percentages to quotesPercentage state when input changes
  const updateQuotesPercentages = (val, quoteId) => {
    const updatedQuotes = quotesPercentages.map((quote) => {
      if (quote.id === quoteId) {
        return { ...quote, percentage: Number(val) };
      }
      return quote;
    });
    setQuotePercentages(updatedQuotes);
  };

  return (
    <>
      <div className="d-flex w-100">
        <div>
          <div
            className={`${styles.dropdown}`}
            style={{ marginRight: "auto" }}
            title="Download Chart Image"
          >
            <IoIosDownload className={styles.downloadIcon} />
            <div className={styles.dropdownContent}>
              <p onClick={() => handleDownloadImage("table", tableRef)}>
                Download as PNG
              </p>
              <p onClick={() => handleDownloadSVG("table", tableRef)}>
                Download as SVG
              </p>
            </div>
          </div>
          <span
            className={styles.chartTitle}
            style={{ fontFamily: "LarkenMedium" }}
          >
            Compare with existing portfolio
          </span>
        </div>

        {/*
        <div
          style={{
            marginTop: "0.0em",
            marginLeft: "auto",
            marginBottom: "2rem",
          }}
        ></div>
        */}
      </div>
      <Row className="justify-content-center">
        <Col xl={2}>
          <div className={styles.formInputs}>
            <p style={{ color: "#6D9E93" }}>
              <strong>Investment</strong>
            </p>
            <input
              type="number"
              onChange={(e) => setInvestment(e.target.value)}
              value={investment}
            />
          </div>
        </Col>
        {quotesPercentages?.map((quote) => (
          <Col key={quote.id} xl={2}>
            <div className={styles.formInputs}>
              <p style={{ color: "#6D9E93" }}>
                <strong>{spyBndxStringHandler(quote.symbol)}</strong>{" "}
                <span>
                  {Number.isInteger(quote.percentage)
                    ? quote.percentage
                    : quote.percentage.toFixed(2)}
                  %
                </span>
              </p>
              <input
                type="number"
                onChange={(e) =>
                  updateQuotesPercentages(e.target.value, quote.id)
                }
                value={quote.percentage}
              />
              {!fulfilledPercentage && (
                <p className={styles.alertMessage}>
                  Total Percentage Unfulfilled
                </p>
              )}
            </div>
          </Col>
        ))}
        <Col xl={2}>
          <div className={`${styles.formInputs} text-align-center`}>
            <p style={{ color: "#6D9E93" }}>
              <strong>Show Advanced Analysis</strong>
            </p>
            <label
              className={`${styles.checkbox}`}
            >
              <input
                type="checkbox"
                onClick={(e) => setShowAdvancedAnalysis(e.target.checked)}
                defaultChecked={showAdvancedAnalysis}
              />
            </label>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default BenchmarkTableInputs;
