import React, { useState } from "react";
import styles from "./Averages.module.scss"
import { useSandboxCurrencies } from "../../../query-hooks/dashboard-query-hooks/useDashboard";
import { useAveragePrices } from "../../../query-hooks/dashboard-query-hooks/useDashboard";
import DashboardComponent from "../../../Components/DashboardComponent";
import { Row, Col } from "react-bootstrap";
import { AveragesTableComponent } from "./AveragesTableComponent";
import { ErrorLoading, Spinner } from "../../../Helpers/Helpers";
import { BiSelectMultiple } from "react-icons/bi";


const Averages = () => {
    const [ selectedCrypto, setSelectedCrypto ] = useState()
    const [ orderDirection, setOrderDirection ] = useState()
    const currencies = useSandboxCurrencies()
    const averagePrices = useAveragePrices(selectedCrypto, orderDirection)

    return (
        <DashboardComponent title="Averages Dashboard" subtitle="Statistics">
            <Row>
                <Col xl={4} className="my-2">
                    <select className={styles.selectInput} onChange={e => setSelectedCrypto(e.target.value)} defaultValue="1">
                        <option value="1" disabled>Select</option>
                        {
                            currencies.isSuccess && currencies.data.sort((a, b) => a.name.localeCompare(b.name)).map(currency => (<option key={currency.id} value={currency.id}>{ currency.name }</option>))
                        }
                    </select>
                </Col>
                <Col xl={4} className="my-2">
                    <select className={styles.selectInput} onChange={e => setOrderDirection(e.target.value)} defaultValue="1">
                        <option value="1" disabled>Select</option>
                        <option value="Buy">Average Entry Prices</option>
                        <option value="Sell">Average Exit Prices</option>
                    </select>
                </Col>
            </Row>
            <Row>

            { !selectedCrypto || !orderDirection ? 
                <div className={styles.selectMessage}>
                    <BiSelectMultiple />
                    <h5>Select Currency and Order Direction</h5> 
                </div> : ""
            }
            
            </Row>
            { averagePrices.isSuccess && <AveragesTableComponent averagePrices={averagePrices.data} orderDirection={orderDirection} /> }
            { averagePrices.isLoading && <Spinner /> }
            { averagePrices.isError && <ErrorLoading /> }
        </DashboardComponent>
    );
};
export default Averages;
