import { useEffect, useState } from "react";

export function useSortedList<T>(initialData: T[]) {
  const [sortProp, setSortProp] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortedData, setSortedData] = useState<T[]>(initialData);

  useEffect(()=>{
    setSortedData(initialData);
  }, [initialData])

  const handleSort = (prop: string) => {
    if (prop === "actions") return;

    const isSameProp = sortProp === prop;
    const newSortOrder = isSameProp && sortOrder === "asc" ? "desc" : "asc";

    const sorted = [...sortedData].sort((a: any, b: any) => {
      const aValue = a[prop] ?? "";
      const bValue = b[prop] ?? "";

      if (typeof aValue === "string" && typeof bValue === "string") {
        return newSortOrder === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      if (typeof aValue === "number" && typeof bValue === "number") {
        return newSortOrder === "asc" ? aValue - bValue : bValue - aValue;
      }

      return 0;
    });

    setSortedData(sorted);
    setSortProp(prop);
    setSortOrder(newSortOrder);
  };

  return { sortedData, handleSort, sortProp, sortOrder };
}