import React, { useState, useEffect, useRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useBlockchainIds } from '../../query-hooks/external-query-hooks/useExternal';
import styles from '../../dashboard/Cryptos/CryptoSearchInput.module.scss';
import { useAuth } from '../../Hooks/useAuth';

interface SourceIdInputProps {
    source: string | null | undefined;  // Updated to match the actual type
    register: UseFormRegisterReturn;
    defaultValue?: string|null|undefined; // Add this prop
}

export function SourceIdInput({ source, register, defaultValue }: SourceIdInputProps) {
    const [inputValue, setInputValue] = useState(defaultValue || '');

    // Add this effect to update inputValue when defaultValue changes
    useEffect(() => {
        if (defaultValue) {
            setInputValue(defaultValue);
        }
    }, []);

    const [showDropdown, setShowDropdown] = useState(false);
    const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);
    const dropdownRef = useRef<HTMLUListElement>(null);

    const { darkmode } = useAuth();
    // Only fetch if source is defined
    const { data: blockchainIds, isLoading } = useBlockchainIds(source || '');

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) &&
                inputRef.current && !inputRef.current.contains(event.target as Node)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue(value);
        register.onChange(e);

        if (!blockchainIds) return;

        const filtered = blockchainIds.filter(id =>
            id.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredSuggestions(filtered);
        setShowDropdown(true);
    };

    const handleSuggestionClick = (suggestion: string) => {
        setInputValue(suggestion);
        
        // Create a synthetic event
        const syntheticEvent = {
            target: {
                value: suggestion,
                name: register.name
            },
            type: 'change',
        } as React.ChangeEvent<HTMLInputElement>;

        // Trigger the form change
        register.onChange(syntheticEvent);
        setShowDropdown(false);
    };

    useEffect(() => {
        const adjustDropdownPosition = () => {
            if (inputRef.current && dropdownRef.current) {
                const inputRect = inputRef.current.getBoundingClientRect();
                const dropdownRect = dropdownRef.current.getBoundingClientRect();
                const viewportHeight = window.innerHeight;
                const dropdownBottom = inputRect.bottom + dropdownRect.height;

                if (dropdownBottom > viewportHeight) {
                    dropdownRef.current.style.top = `-${dropdownRect.height + 2}px`; // Adjust for spacing
                } else {
                    dropdownRef.current.style.top = `${inputRect.height}px`;
                }
            }
        };

        if (showDropdown) {
            requestAnimationFrame(adjustDropdownPosition);
        }
    }, [showDropdown]);

    return (
        <div>
            <input
                {...register}
                value={inputValue} // Add this line
                ref={(e)=>{
                    register.ref(e);
                    if(inputRef){
                        // @ts-ignore
                        inputRef.current = e;
                    }
                }}
                className={styles.mainInput}
                placeholder="Source Id"
                onChange={handleInputChange}
                onFocus={() => blockchainIds?.length && setShowDropdown(true)}
            />
            {showDropdown && isLoading && (
              <ul className={darkmode ? styles.listFrameDark : styles.listFrame}>
                  <li className={darkmode?styles.listItemsDark : styles.listItems}>Loading...</li>
              </ul>
            )}
            {showDropdown && filteredSuggestions.length > 0 && (
                <ul 
                    ref={dropdownRef}
                    className={darkmode ? styles.listFrameDark : styles.listFrame}
                >
                    {filteredSuggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            className={darkmode ? styles.listItemsDark : styles.listItems}
                            onClick={() => handleSuggestionClick(suggestion)}
                        >
                            {suggestion}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}