import { BenchmarkCandle } from "../model/benchmarkCandle";

export function annualizedVolatility(weightedSums: BenchmarkCandle[]) {
    if (weightedSums.length < 2) {
        return 0;
    }

    // Calculate periodic returns
    const periodicReturns = [];
    for (let i = 1; i < weightedSums.length; i++) {
        const previous = weightedSums[i - 1].normalizedValue;
        const current = weightedSums[i].normalizedValue;
        const periodicReturn = (current - previous) / previous;
        periodicReturns.push(periodicReturn);
    }

    // Calculate mean of returns
    const meanReturn = periodicReturns.reduce((sum, return_) => sum + return_, 0) 
        / periodicReturns.length;

    // Calculate variance
    const squaredDiffs = periodicReturns.map(return_ => 
        Math.pow(return_ - meanReturn, 2)
    );
    const variance = squaredDiffs.reduce((sum, diff) => sum + diff, 0) 
        /// (periodicReturns.length - 1);  // Using n-1 for sample standard deviation
        / (periodicReturns.length);

    // Since we have monthly data, we use 12 periods per year
    const periodsPerYear = 12;

    // Annualize volatility
    return Math.sqrt(variance * periodsPerYear);
}
