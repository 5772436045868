import React, { useState } from 'react';
import { useManualBankAccountBalanceUpdate, confirmAndUpdateBankBalance } from '../../../../query-hooks/balance-reconciliation-hooks/useBalanceReconciliation';
import { ReconciliationListViewModel, BankListViewModel, BankAccountListViewModel } from '@hodl.nl/hodltradingdesk';
import styles from './ReconciliationTable.module.scss';
import { useAuth } from '../../../../Hooks/useAuth';
import { useBanks, useBankAccounts } from '../../../../query-hooks/bank-query-hooks/useBank';
import Swal from 'sweetalert2';

interface ManualBankBalanceUpdateProps {
    data: ReconciliationListViewModel;
    fundId?: string;
}

interface PagingViewModel<T> {
    items?: T[] | null;
    pageNumber?: number;
    pageSize?: number;
    totalItems?: number;
    totalPages?: number;
}

export function UpdateBankBalanceRow({ data, fundId }: ManualBankBalanceUpdateProps) {
    const { darkmode } = useAuth();
    const mutation = useManualBankAccountBalanceUpdate();
    const [selectedBank, setSelectedBank] = useState<string|undefined>(undefined);
    const [selectedBankAccount, setSelectedBankAccount] = useState('');
    const [newBalance, setNewBalance] = useState('');

    const { data: banks, isLoading: isBanksLoading } = useBanks();
    const { data: bankAccounts, isLoading: isBankAccountsLoading } = useBankAccounts(selectedBank);

    if (!data.holding?.currencyISOCode || !data.bankAccountBalances || !fundId) {
        return null;
    }

    // Get existing bank account IDs to filter them out
    const existingBankAccountIds = new Set(data.bankAccountBalances.map(b => b.bankAccount?.id));

    const handleSubmit = () => {
        // Validation checks
        if (!selectedBank) {
            Swal.fire({
                title: 'Error',
                text: 'Please select a bank',
                icon: 'error',
                confirmButtonColor: '#3085d6'
            });
            return;
        }

        if (!selectedBankAccount) {
            Swal.fire({
                title: 'Error',
                text: 'Please select a bank account',
                icon: 'error',
                confirmButtonColor: '#3085d6'
            });
            return;
        }

        if (!newBalance) {
            Swal.fire({
                title: 'Error',
                text: 'Please enter a balance',
                icon: 'error',
                confirmButtonColor: '#3085d6'
            });
            return;
        }

        const details = {
            bankAccountId: selectedBankAccount,
            currencyIsoCode: data.holding!.currencyISOCode!,
            newBalance: newBalance
        };

        confirmAndUpdateBankBalance(details, mutation);

        // Reset form after submission
        setSelectedBank(undefined);
        setSelectedBankAccount('');
        setNewBalance('');
    };

    // Filter banks that have accounts with matching fundId
    const validBanks = (banks as PagingViewModel<BankListViewModel>)?.items?.filter((bank: BankListViewModel) => 
        bank.id != null
    ) ?? [];

    // Filter bank accounts based on fundId and existing balances
    const validBankAccounts = (bankAccounts as PagingViewModel<BankAccountListViewModel>)?.items?.filter((account: BankAccountListViewModel) => 
        account.id != null && 
        !existingBankAccountIds.has(account.id) &&
        account.fund?.id === fundId
    ) ?? [];

    return (
        <tr className={`${styles.detailRow} ${darkmode ? styles.dark : ''}`}>
            <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>
                <span className={styles.label}>Add Bank Balance</span>
            </td>
            <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>
                <select 
                    value={selectedBank}
                    onChange={(e) => {
                        setSelectedBank(e.target.value);
                        setSelectedBankAccount(''); // Reset bank account when bank changes
                    }}
                    className={`${styles.select} ${darkmode ? styles.dark : ''}`}
                    disabled={isBanksLoading}
                >
                    <option value="">Select Bank</option>
                    {validBanks.map((bank: BankListViewModel) => (
                        <option key={bank.id} value={bank.id}>
                            {bank.name}
                        </option>
                    ))}
                </select>
            </td>
            <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>
                <select 
                    value={selectedBankAccount}
                    onChange={(e) => setSelectedBankAccount(e.target.value)}
                    className={`${styles.select} ${darkmode ? styles.dark : ''}`}
                    disabled={!selectedBank || isBankAccountsLoading}
                >
                    <option value="">Select Bank Account</option>
                    {validBankAccounts.map((account: BankAccountListViewModel) => (
                        <option key={account.id} value={account.id}>
                            {account.holderName} - {account.iban}
                        </option>
                    ))}
                </select>
            </td>
            <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>
                <input
                    type="number"
                    step="any"
                    value={newBalance}
                    onChange={(e) => setNewBalance(e.target.value)}
                    placeholder="New Balance"
                    className={`${styles.input} ${darkmode ? styles.dark : ''}`}
                    disabled={!selectedBankAccount}
                />
            </td>
            <td></td>
            <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>
                <button 
                    type="button"
                    onClick={handleSubmit}
                    disabled={!selectedBankAccount || !newBalance}
                    className={`${styles.updateButton} ${darkmode ? styles.dark : ''}`}
                >
                    Update
                </button>
            </td>
        </tr>
    );
}
