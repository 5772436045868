export interface IBenchmarkCandle {
  change: number;
  date: string;
  normalizedValue: number;
  price: number;
}

export class BenchmarkCandle implements IBenchmarkCandle {
  change: number;
  date: string;
  normalizedValue: number;
  price: number;

  constructor(bc: IBenchmarkCandle) {
    this.change = bc.change;
    this.date = bc.date;
    this.normalizedValue = bc.normalizedValue;
    this.price = bc.price;
  }

  public get navPeriod(): string {
    const [year, month] = this.date.split("-");
    return `${year}${month}`;
  }
}

export interface WeightedBenchmark {
    name: string;
    id: string;
    candles: BenchmarkCandle[];
    weight: number;
}