import React, { createContext, useContext, useMemo } from 'react'
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import axios from "axios";
import { LOGIN_API_URL } from "../settings";
import { AlertFunction, getTimeToRefresh } from '../Helpers/Helpers';
import Swal from 'sweetalert2';

const AuthContext = createContext();
// Timer to refresh token
let tokenResetTimer;

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [darkmode, setDarkmode] = useLocalStorage("darkmode", true)
  const [ chosenOrderNumber, setChosenOrderNumber ] = useLocalStorage("chosenOrderNumber", "") // State of chosen Order Number in Orders Dashboard Dynamic Search input
  const [ chosenOrderTicker, setChosenOrderTicker ] = useLocalStorage("chosenOrderTicker", "") // State of chosen Order Ticker in Orders Dashboard Dynamic Search input
  const [ fundedOrdersFilter, setFundedOrdersFilter ] = useLocalStorage("fundedOrderFilter", false) // State of chosen Funded OrdersFilter in Orders Dashboard Dynamic Search input
  const [ selectedBenchmarkFunds, setSelectedBenchmarkFunds ] = useLocalStorage("benchmarkFunds", []) // State for default Funds at Benchmarks form
  const [ bookingPeriod, setBookingPeriod ] = useLocalStorage("bookingPeriod", "") // State for consistent booking period in Funds Dashboard
  const [ transferFormProps, setTransferFormProps ] = useLocalStorage("transferFormProps", null)

  const navigate = useNavigate();

  const refreshToken = timeToExpire => {
    // Clear previous timer first
    clearTimeout(tokenResetTimer)

    tokenResetTimer = setTimeout(() => {
      return axios.get(`${LOGIN_API_URL}/auth/refresh`)
        .then(res => {
          storeUser(res.data)
        })
    }, [timeToExpire])
  }

  // call this function when you want to authenticate the user
  const login = async (data) => {
    storeUser(data)
  }

  // call this function to sign out logged in user
  const logout = () => {
    // Execute the logout api call and then reset the UI
    axios
    .post(`${LOGIN_API_URL}/auth/logout`)
    .then(() => {
      storeUser(null)
      navigate("/", { replace: true })
      setDarkmode(true)
      setChosenOrderNumber("")
      setChosenOrderTicker("")
      setFundedOrdersFilter(false)
    })
  };

  // Axios interceptors to handle errors for each error code
  axios.interceptors.response.use((response) => response, async (error) => {
    const statusCode = error.response ? error.response.status : null;

    // Handling 401 error different responses
    if (statusCode === 401) {
        switch (error.response.data.errors ? error.response.data.errors[0].code : '')
        {
          case "RequiresEmailConfirmation":
            setUser({email: error.config.headers.email})
            window.location.href = `/authentication`
            return
          case "RequiresMultiFactorCode":
            window.location.href = '/mfa-check'
            return
          case "AccountLocked":
            storeUser(null)
            AlertFunction(error)
            return
          default:
            storeUser(null)
            window.location.href = '/'
            return
        }
      }

    if (statusCode === 403) {
      window.location.href = '/'
      return
    }

    // if (statusCode === 409 && error.response.data.errors.some(x => x.code === "ItemIsReferenced")) {
    //   return Promise.reject(error);
    // }
    if (Array.isArray(error.response.data.errors)) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.errors.map(err => err.field),
          text: error.response.data.errors.map(err => err.description),
          confirmButtonColor: '#6d9e93',
        })
        return
    }

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    if (statusCode === 400) {
      var keys = Object.keys(error.response.data.errors);
      Toast.fire({
        title: "Validation errors",
        text: error.response.data.errors[keys[0]][0]
      });
      return;
    }

    if (error.response.title || error.response.data.title || error.response.data.description) {
      Toast.fire({
        icon: 'error',
        title: error.response.data.title,
        text: error.response.data.description,
      })
      return
    }

    Toast.fire({
      icon: 'error',
      title: error.response.data,
    })


    return Promise.reject(error);
  });


  // Function to store user in Local Storage
  const storeUser = (data) => {

    // Refresh the user info, it is changed
    if (user !== data) {
      setUser(data)
    }

    // Check if the user is null, logout
    if (data === null || data === undefined) {
      // Remove the axios Authorization token
      clearTimeout(tokenResetTimer)
      delete axios.defaults.headers.common['Authorization']
    } else {
      // Set the Axios Authorization token and start the refresh
      axios.defaults.headers.common['Authorization'] = data.token
      refreshToken(getTimeToRefresh(data.expirationTime)) // Call function to refresh in x miliseconds based on expiration time
    }
  }

  // console.log(user)

  // Function to handle Darkmode
  const handleDarkmode = () => setDarkmode(!darkmode)

  const value = useMemo(
    () => ({
      user,
      setUser,
      login,
      logout,
      darkmode,
      handleDarkmode,
      storeUser,
      chosenOrderNumber,
      setChosenOrderNumber,
      chosenOrderTicker,
      setChosenOrderTicker,
      fundedOrdersFilter,
      setFundedOrdersFilter,
      selectedBenchmarkFunds,
      setSelectedBenchmarkFunds,
      bookingPeriod,
      setBookingPeriod,
      transferFormProps,
      setTransferFormProps,
      axiosInstance: axios
    }),
    [user, darkmode, storeUser]
  );

  // We have refetched the user status from local storage, if logged in we must
  // also set the axios authorization token
  if (user && user.token) {
    storeUser(user)
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
};

export const useAuth = () => {
  return useContext(AuthContext)
};
