export const TRANSACTIONTYPES = [
    {
      name: "Airdrop",
      value: "Airdrop",
    },
    {
      name: "BookReservations",
      value: "BookReservations"
    },
    {
      name: "Bank",
      value: "Bank",
    },
    {
      name: "Broker",
      value: "Broker",
    },
    {
      name: "Commission",
      value: "Commission",
    },
    {
      name: "Correction",
      value: "Correction",
    },
    {
      name: "Costs",
      value: "Costs",
    },
    {
      name: "Inflow",
      value: "Inflow",
    },
    {
      name: "Loss",
      value: "Loss",
    },
    {
      name: "Management Fee",
      value: "ManagementFee",
    },
    {
      name: "Outflow",
      value: "Outflow",
    },
    {
      name: "Performance Fee",
      value: "PerformanceFee",
    },
    {
      name: "Profit",
      value: "Profit",
    },
    {
      name: "Reward",
      value: "Reward",
    },
    {
      name: "Transfer",
      value: "Transfer",
    },
    {
      name: "Wallet",
      value: "Wallet",
    },
  ];
  
  export const ORDERDIRECTION = [
    {
      name: "Buy",
      value: "Buy"
    },
    {
      name: "Sell",
      value: "Sell"
    },
  ];
  
  export const ORDERSTATES = [
    {
      name: "Unknown",
      value: "Unknown",
    },
    {
      name: "New",
      value: "New",
    },
    {
      name: "Submitted",
      value: "Submitted",
    },
    {
      name: "Trigger Pending",
      value: "TriggerPending",
    },
    {
      name: "Active",
      value: "Active",
    },
    {
      name: "Change Pending",
      value: "ChangePending",
    },
    {
      name: "Cancel Pending",
      value: "CancelPending",
    },
    {
      name: "Cancelled",
      value: "Cancelled",
    },
    {
      name: "Rejected",
      value: "Rejected",
    },
    {
      name: "Part Filled",
      value: "PartFilled",
    },
    {
      name: "Filled",
      value: "Filled",
    },
    {
      name: "Expired",
      value: "Expired",
    },
  ];

  export const fundHoldingsTableHeaders = (reportingCurrencyCode, displayCurrencyFundReporting) => {
    if (displayCurrencyFundReporting === "reportingCurrency") {
      return [
        { name: `Start ${reportingCurrencyCode} Price`, value: "startUSDPrice" },
        { name: "Start Balance", value: "startBalance" },
        { name: `End ${ reportingCurrencyCode } Price`, value: "endUSDPrice" },
        { name: "End Balance", value: "endBalance" },
        { name: `End ${ reportingCurrencyCode } Value`, value: "endUSDValue" },
        { name: "End BTC Value", value: "endBTCValue" },
      ]
    }
    if (displayCurrencyFundReporting === "usdCurrency") {
      return [
        { name: "Start USD Price", value: "startUSDPrice" },
        { name: "Start Balance", value: "startBalance" },
        { name: "End USD Price", value: "endUSDPrice" },
        { name: "End Balance", value: "endBalance" },
        { name: "End USD Value", value: "endUSDValue" },
        { name: "End BTC Value", value: "endBTCValue" },
      ]
  
    }
  } 

  export const FUND_CLOSE_DAY = [
    {
      name: "Last Day",
      value: "LastDay"
    },
    {
      name: "First Day",
      value: "FirstDay"
    },
    {
      name: "Last Working Day",
      value: "LastWorkingDay"
    },
    {
      name: "First Working Day",
      value: "FirstWorkingDay"
    }
  ]

  export const blockchainNetworkFilters = [
    "All", 
    "Mainnet", 
    "Testnet"
  ];
  