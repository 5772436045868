import { BenchmarkCandle } from "./model/benchmarkCandle";
import { maxDrawdown } from "./raw/maxDrawdown";
import { IndicatorResult } from "./model/indicatorResult";
import React from 'react';

export function getMaxDrawdownResult(input: BenchmarkCandle[]): IndicatorResult {
  const value = maxDrawdown(input);
  return {
    title: "Max Drawdown",
    value: `${(value * 100).toFixed(2)}%`,
    tooltip: "Largest peak-to-trough decline",
    rawValue: value
  };
}

interface Props {
  input: BenchmarkCandle[];
}

export const MaxDrawdown: React.FC<Props> = ({ input }) => {
  const result = getMaxDrawdownResult(input);
  return <p>{result.value}</p>;
};