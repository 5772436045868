import { BenchmarkCandle } from "../model/benchmarkCandle";

/**
 * Calculates the skewness of returns distribution
 * @param weightedSums Array of benchmark candles with normalized values
 * @returns Skewness coefficient (0 is symmetric, >0 right tail, <0 left tail)
 */
export function skewness(weightedSums: BenchmarkCandle[]): number {
    if (weightedSums.length < 3) { // Need at least 3 points for meaningful skewness
        return 0;
    }

    // Calculate returns
    const returns = [];
    for (let i = 1; i < weightedSums.length; i++) {
        const previous = weightedSums[i - 1].normalizedValue;
        const current = weightedSums[i].normalizedValue;
        const return_ = (current - previous) / previous;
        returns.push(return_);
    }

    // Calculate mean
    const mean = returns.reduce((sum, r) => sum + r, 0) / returns.length;

    // Calculate second and third moments
    let m2 = 0; // variance * n
    let m3 = 0; // third moment * n
    
    returns.forEach(return_ => {
        const deviation = return_ - mean;
        const squaredDeviation = deviation * deviation;
        m2 += squaredDeviation;
        m3 += squaredDeviation * deviation;
    });

    // Calculate skewness using Fisher-Pearson standardized moment coefficient
    const n = returns.length;
    const variance = m2 / n;
    const standardDeviation = Math.sqrt(variance);
    
    if (standardDeviation === 0) return 0;
    
    // Adjusted Fisher-Pearson standardized moment coefficient
    const skewnessValue = (n * m3) / ((n - 1) * (n - 2) * Math.pow(standardDeviation, 3));

    return skewnessValue;
}
