import React, { useState, useEffect } from "react";
import { Row, Col, Table } from 'react-bootstrap'
import { Link } from "react-router-dom";
import styles from "../../FundDetail.module.scss"
import { useAuth } from "../../../../Hooks/useAuth";
import { numberEditor, totalSummary } from "../../../../Helpers/Helpers";
import { useDeleteFundHolding, confirmationAlertDeleteFundHolding, useFundHoldingsByDates } from "../../../../query-hooks/fund-query-hooks/useFund"
import { API_URL } from '../../../../settings';
import Logo from "../../../../images/Hodl-logo.jpg"
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import FundHoldingsCardTHeaders from "../FundsHoldingFormState/Components/FundHoldingsCardTHeaders";
import { FaSort } from "react-icons/fa";
import { fundHoldingsTableHeaders } from "../../../../constants/enums";

const HoldingsTable = ({ reportingCurrencyCode, fundId, bookingPeriod, selectedFundClassId }) => {
  const { darkmode } = useAuth()
  const [ sortProp, setSortProp ] = useState()
  const [ queryString, setQueryString ] = useState()
  const [ lowBalance, setLowBalance ] = useState(false)
  const fundHoldingsByDates = useFundHoldingsByDates(fundId, bookingPeriod, bookingPeriod, sortProp, queryString, lowBalance, selectedFundClassId)
  const mutation = useDeleteFundHolding()
  const handleImageError = (e) => e.target.src = Logo
  const [ reportingCurrencyUSDValue, setReportingCurrencyUSDValue ] = useState()
  const [ displayCurrencyFundReporting, setDisplayCurrencyFundReporting ] = useState("reportingCurrency")

  useEffect(() => {
    fundHoldingsByDates.isSuccess && setReportingCurrencyUSDValue(fundHoldingsByDates.data.find(holding => holding.symbol === reportingCurrencyCode)?.endUSDPrice)
  }, [fundHoldingsByDates.data, reportingCurrencyCode])

  return (
    <>
      <div className={styles.holdingsHeader}>
        <Row>
          <Col lg={3}>
            <input
              className={styles.searchInput}
              onChange={(e) => setQueryString(e.target.value)}
              placeholder="Search Holding..."
            />
          </Col>
          <Col lg={2}>
            <select
              className={darkmode ? styles.selectInputDark : styles.selectInput}
              defaultValue={displayCurrencyFundReporting}
              onChange={(e) => setDisplayCurrencyFundReporting(e.target.value)}
            >
              <option value="reportingCurrency">{reportingCurrencyCode}</option>
              <option value="usdCurrency">USD</option>
            </select>
          </Col>
          <Col lg={3}>
            <label className={styles.checkbox}>
              <input
                type="checkbox"
                onClick={(e) => setLowBalance(e.target.checked)}
              />
              <span style={{ fontSize: "12px" }}>Filter by Low Balance</span>
            </label>
          </Col>
          <Col lg={4} className={styles.addContainer}>
            Add a Fund Holding
            <Link
              className={styles.addIcon}
              to={`/main/funds/create-fund-holding/${selectedFundClassId ? selectedFundClassId : fundId}`}
            >
              +
            </Link>
          </Col>
        </Row>
      </div>
      <Table borderless={true}>
        <colgroup>
          <col span="5" />
          <col
            span="1"
            style={{ backgroundColor: "#222f3f", border: "solid 1px #314650" }}
          />
        </colgroup>
        <thead className={styles.thead} style={{ top: "4.5rem" }}>
          <tr className={styles.theader}>
            <th></th>
            <th style={{ textAlign: "left" }} onClick={() => setSortProp()}>
              Coins <FaSort style={{ fontSize: "18px" }} />
            </th>
            {fundHoldingsTableHeaders(
              reportingCurrencyCode,
              displayCurrencyFundReporting,
            ).map((header) => (
              <FundHoldingsCardTHeaders
                key={header.value}
                header={header}
                sortProp={sortProp}
                setSortProp={setSortProp}
              />
            ))}
            <th></th>
            <th></th>
          </tr>
        </thead>
        {fundHoldingsByDates.isSuccess &&
          fundHoldingsByDates.data.map((holding) => (
            <tbody key={holding.id}>
              <tr
                className={
                  darkmode ? styles.tableStripsDark : styles.tableStrips
                }
              >
                <td>
                  {holding.cryptoCurrency ? (
                    <img
                      key={Date.now()}
                      src={`${API_URL}/cryptos/${holding.cryptoCurrency.id}/icon`}
                      width="25px"
                      onError={handleImageError}
                      className={styles.cryptoIcons}
                      alt="crypto"
                    />
                  ) : holding.currency ? (
                    <h5>{holding.currency.symbol}</h5>
                  ) : (
                    <h5></h5>
                  )}
                </td>
                <td style={{ textAlign: "left" }}>
                  <span style={{ color: "#6D9E93" }}>
                    {holding.displayName}
                  </span>
                </td>
                <td
                  title={
                    holding.startUSDPrice / reportingCurrencyUSDValue || ""
                  }
                >
                  {displayCurrencyFundReporting === "reportingCurrency"
                    ? numberEditor(
                        holding.startUSDPrice / reportingCurrencyUSDValue,
                      )
                    : numberEditor(holding.startUSDPrice)}
                </td>
                <td title={holding.startBalance}>
                  {numberEditor(holding.startBalance)}{" "}
                </td>
                <td
                  title={holding.endUSDPrice / reportingCurrencyUSDValue || ""}
                >
                  {displayCurrencyFundReporting === "reportingCurrency"
                    ? numberEditor(
                        holding.endUSDPrice / reportingCurrencyUSDValue,
                      )
                    : numberEditor(holding.endUSDPrice)}
                </td>
                <td title={holding.endBalance}>
                  {numberEditor(holding.endBalance)}
                </td>
                <td
                  title={holding.endUSDValue / reportingCurrencyUSDValue || ""}
                >
                  {displayCurrencyFundReporting === "reportingCurrency"
                    ? numberEditor(
                        holding.endUSDValue / reportingCurrencyUSDValue,
                      )
                    : numberEditor(holding.endUSDValue)}
                </td>
                <td title={holding.endBTCValue}>
                  {numberEditor(holding.endBTCValue)}
                </td>
                <td className={styles.tooltip}>
                  <Link
                    className={styles.icons}
                    to={`/main/funds/edit-holding/${selectedFundClassId ? selectedFundClassId : fundId}/${holding.id}`}
                  >
                    <AiOutlineEdit />
                    <span className={styles.tooltiptext}>Edit</span>
                  </Link>
                </td>
                <td
                  className={styles.tooltip}
                  onClick={(e) =>
                    confirmationAlertDeleteFundHolding(
                      e,
                      fundId,
                      holding.id,
                      mutation,
                    )
                  }
                >
                  <AiOutlineDelete className={styles.icons} />
                  <span className={styles.tooltiptext}>Delete</span>
                </td>
              </tr>
            </tbody>
          ))}
        <tfoot>
          <tr
            className={darkmode ? styles.tableStripsDark : styles.tableStrips}
            style={{
              fontWeight: "300",
              backgroundColor: "#222f3f",
              border: "solid 1px #314650",
            }}
          >
            <th colSpan="6" style={{ fontWeight: "900" }}>
              Total :
            </th>
            <td>
              {fundHoldingsByDates.isSuccess &&
                totalSummary(fundHoldingsByDates.data, "endUSDValue")}
            </td>
            <td>
              {fundHoldingsByDates.isSuccess &&
                totalSummary(fundHoldingsByDates.data, "endBTCValue")}
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default HoldingsTable;
