import { BenchmarkCandle } from "./model/benchmarkCandle";
import { sharpeRatio } from "./raw/sharpeRatio";
import { IndicatorResult } from "./model/indicatorResult";
import React from 'react';

export function getSharpeRatioResult(input: BenchmarkCandle[]): IndicatorResult {
  const value = sharpeRatio(input);
  return {
    title: "Sharpe Ratio",
    value: value.toFixed(2),
    tooltip: "Risk-adjusted return metric comparing returns above risk-free rate to volatility",
    rawValue: value
  };
}

interface Props {
  input: BenchmarkCandle[];
  data?: any;
  investment?: number;
  fund?: any;
  fundPercentage?: number;
  quotesPercentages?: any[];
  benchmarkData?: any;
  setZeroPercentSharpeRatio?: (value: number) => void;
  zeroPercentSharpeRatio?: number;
}

export const SharpeRatio: React.FC<Props> = ({ input }) => {
  const result = getSharpeRatioResult(input);
  return <p>{result.value}</p>;
};