import React, { useState } from 'react';
import styles from './ReconciliationTable.module.scss';
import { useAuth } from '../../../../Hooks/useAuth';
import { walletAddressEditor } from '../../../../Helpers/Helpers';
import { useManualBankAccountBalanceUpdate, useManualWalletBalanceUpdate } from '../../../../query-hooks/balance-reconciliation-hooks/useBalanceReconciliation';
import { FaPencilAlt, FaTimes, FaCloudUploadAlt } from 'react-icons/fa';
import { ExtendedHoldingReconciliationListViewModel } from './ReconciliationTable';
import Swal from 'sweetalert2';

interface ReconciliationDetailsProps {
    data: ExtendedHoldingReconciliationListViewModel;
}

interface EditState {
    type: 'wallet' | 'bank';
    index: number;
    value: string;
}

function sortBalances(a: number|undefined, b: number | undefined) {
    return (a ?? 0) < (b ?? 0) ? 1 : -1;
}

export function ReconciliationDetails({ data }: ReconciliationDetailsProps) {
    const { darkmode } = useAuth();
    const [editState, setEditState] = useState<EditState | null>(null);
    const walletBalances = data.walletBalances?.filter(x => x.balance != 0) || [];
    walletBalances.sort((a, b)=> sortBalances(a.balance, b.balance));
    const bankBalances = data.bankAccountBalances?.filter(x => x.balance != 0) || [];
    bankBalances.sort((a, b)=> sortBalances(a.balance, b.balance));
    const allBalances = [...walletBalances, ...bankBalances];

    const walletMutation = useManualWalletBalanceUpdate();
    const bankMutation = useManualBankAccountBalanceUpdate();

    const handleEdit = (type: 'wallet' | 'bank', index: number, currentBalance: number) => {
        setEditState({
            type,
            index,
            value: currentBalance.toFixed(8)
        });
    };

    const handleCancel = () => {
        setEditState(null);
    };

    const handleSave = () => {
        if (!editState) return;

        // Validate the balance value
        if (!editState.value || editState.value.trim() === '') {
            Swal.fire({
                title: 'Error',
                text: 'Please enter a balance value',
                icon: 'error',
                confirmButtonColor: '#3085d6'
            });
            return;
        }

        const numValue = parseFloat(editState.value);
        if (isNaN(numValue)) {
            Swal.fire({
                title: 'Error',
                text: 'Please enter a valid number',
                icon: 'error',
                confirmButtonColor: '#3085d6'
            });
            return;
        }

        if (editState.type === 'wallet' && numValue < 0) {
            Swal.fire({
                title: 'Error',
                text: 'Balance cannot be negative',
                icon: 'error',
                confirmButtonColor: '#3085d6'
            });
            return;
        }

        if (editState.type === 'wallet') {
            const wallet = walletBalances[editState.index];
            walletMutation.mutate({
                walletAddress: wallet.address || '',
                cryptoCurrencyId: wallet.cryptoCurrency?.id || '',
                blockchainNetworkId: wallet.blockchainNetwork?.id || '',
                newBalance: editState.value
            });
        } else {
            const bank = bankBalances[editState.index];
            bankMutation.mutate({
                bankAccountId: bank.bankAccount?.id || '',
                currencyIsoCode: data.currencyIsoCode || '',
                newBalance: editState.value
            });
        }

        setEditState(null);
    };

    if (allBalances.length === 0) {
        return (
            <tr>
                <td colSpan={5} className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>No balances found</td>
            </tr>
        );
    }

    return (
        <>
            {walletBalances.map((wallet, index) =>
                (
                <tr key={`wallet-${index}`} className={`${styles.detailRow} ${darkmode ? styles.dark : ''}`}>
                    <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>└</td>
                    <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>
                        {
                            wallet.exchangeAccount != null ? wallet.exchangeAccount.name : walletAddressEditor(wallet)
                        }
                        <br />
                        {wallet.blockchainNetwork?.name}
                    </td>
                    <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>
                        {editState?.type === 'wallet' && editState.index === index ? (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <input
                                    type="text"
                                    value={editState.value}
                                    onChange={(e) => setEditState({ ...editState, value: e.target.value })}
                                    className={`${styles.input} ${darkmode ? styles.dark : ''}`}
                                />
                                <FaCloudUploadAlt onClick={handleSave} style={{ cursor: 'pointer' }} />
                                <FaTimes onClick={handleCancel} style={{ cursor: 'pointer' }} />
                            </div>
                        ) : (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                {wallet.balance?.toFixed(8) || '0.00000000'}
                                <FaPencilAlt 
                                    onClick={() => handleEdit('wallet', index, wallet.balance || 0)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        )}
                    </td>
                    <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}></td>
                    <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}></td>
                </tr>
            ))}
            {bankBalances.map((bank, index) => (
                <tr key={`bank-${index}`} className={`${styles.detailRow} ${darkmode ? styles.dark : ''}`}>
                    <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>└</td>
                    <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>{bank.bankAccount?.iban}</td>
                    <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>
                        {editState?.type === 'bank' && editState.index === index ? (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <input
                                    type="text"
                                    value={editState.value}
                                    onChange={(e) => setEditState({ ...editState, value: e.target.value })}
                                    className={`${styles.input} ${darkmode ? styles.dark : ''}`}
                                />
                                <FaCloudUploadAlt onClick={handleSave} style={{ cursor: 'pointer' }} />
                                <FaTimes onClick={handleCancel} style={{ cursor: 'pointer' }} />
                            </div>
                        ) : (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                {bank.balance?.toFixed(8) || '0.00000000'}
                                <FaPencilAlt 
                                    onClick={() => handleEdit('bank', index, bank.balance || 0)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        )}
                    </td>
                    <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}></td>
                    <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}></td>
                </tr>
            ))}
        </>
    );
}
