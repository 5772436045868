import axios from "axios";
import { useQuery, useQueryClient, useMutation } from "react-query";
import Swal from "sweetalert2";
import { API_URL } from "../../settings"
import { WalletApi } from "@hodl.nl/hodltradingdesk";
import {useAuth} from "../../Hooks/useAuth";

// Function to get all wallets
const fetchWallets = (fundId) => axios.get(`${API_URL}/wallets`, {params: {itemsPerPage:100, fundId}}).then((response) => response.data);
/**
 * @param {string|undefined} fundId
 * @returns {import('react-query').UseQueryResult<import('@hodl.nl/hodltradingdesk').WalletListViewModelPagingViewModel, Error>}
 */
export default function useWallets(fundId) {
  return useQuery("wallets", () => fetchWallets(fundId));
}

// Function to get one Wallet by its address
const fetchWallet = (address) => axios.get(`${API_URL}/wallets/${address}`).then((response) => response.data);
export function useWallet(address) {
  return useQuery(["wallet", address], () => fetchWallet(address), {enabled: address !== undefined}  );
}

// Function to get one Wallet Balances by its address
const fetchWalletBalances = (address, page) => axios.get(`${API_URL}/wallets/${address}/balances`, { params: { page: page } }).then(res => res.data);
export const useWalletBalances = (address, page) => useQuery(["walletBalances", address, page], () => fetchWalletBalances(address, page));

// Function to get one Balance by its id
const fetchWalletBalance = (address, balanceId) => axios.get(`${API_URL}/wallets/${address}/balances/${balanceId}`).then((response) => response.data);
export function useWalletBalance(address, balanceId) {
  return useQuery(["walletBalance", address, balanceId], () => fetchWalletBalance(address, balanceId), {enabled: balanceId !== undefined}  );
}

// Function to get all Blockchain Networks
const fetchBlockchainNetworks = () => axios.get(`${API_URL}/networks?page=0&itemsPerPage=100`).then((response) => response.data);

/**
 * 
 * @returns {import('react-query').UseQueryResult<import('@hodl.nl/hodltradingdesk').BlockchainNetworkListViewModelPagingViewModel, Error>}
 */
export const useNetworks = () => useQuery("networks", fetchBlockchainNetworks);

//Function to get all wallets for Order and Transfer Import
const fetchWalletsImport = () => axios.get(`${API_URL}/wallets/select`).then(res => res.data)
export const useWalletsImport = () => useQuery("walletsForImport", fetchWalletsImport)

// MUTATIONS 

// WALLET MUTATIONS
//Function to post Wallet
const postWallet = (wallet) => axios.post(`${API_URL}/wallets`, wallet).then(response => response.data);

export function useCreateWallet() {
  const queryClient = useQueryClient()
  return useMutation(wallet => postWallet(wallet), {
    onSuccess: () => queryClient.invalidateQueries("wallets") 
  });
}

//Function to edit Wallet
const editWallet = (wallet, address) => axios.put(`${API_URL}/wallets/${address}`, wallet).then(response => response.data);

export function useEditWallet() {
  const queryClient = useQueryClient()
  return useMutation(({ wallet, address }) => editWallet(wallet, address), {
      onSuccess: () => queryClient.invalidateQueries("wallets") 
    });
}

//Function to delete Wallet
const deleteWallet = (address) => axios.delete(`${API_URL}/wallets/${address}`).then(response => response.data);
export function useDeleteWallet() {
  const queryClient = useQueryClient()
  return useMutation(({ address }) => deleteWallet(address), 
    { onSuccess: async () => queryClient.invalidateQueries("wallets"), onError: async (error) => Swal.fire(error.response.data.errors[0].description) });
}
// Message object inserted in delete message
const deleteMessageWallet = {
  title: "Are you sure you want to delete this Wallet?", 
  text: "You won't be able to revert this!", 
  icon: "warning", 
  showCancelButton: true, 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Yes, delete it!"
}

export const confirmationAlertDeleteWallet = (event, address, mutation) => {
  event.preventDefault();
  Swal.fire(deleteMessageWallet).then(result => result.isConfirmed && mutation.mutate({ address: address }) );
};


// WALLET BALANCE MUTATIONS
//Function to post Wallet Balance
const postWalletBalance = (walletBalance, address) => axios.post(`${API_URL}/wallets/${address}/balances`, walletBalance).then(response => response.data);

export function usePostWalletBalance() {
  const queryClient = useQueryClient()
  return useMutation(({ walletBalance, address }) => postWalletBalance(walletBalance, address), 
  { onSuccess: async () => queryClient.invalidateQueries("walletBalances"), onError: async (error) => Swal.fire(error.response.data.errors[0].description) });
}

//Function to edit Wallet Balance
const editWalletBalance = (walletBalance, address, balanceId) => axios.put(`${API_URL}/wallets/${address}/balances/${balanceId}`, walletBalance).then(response => response.data);

export function useEditWalletBalance() {
  const queryClient = useQueryClient()
  return useMutation(({ walletBalance, address, balanceId }) => editWalletBalance(walletBalance, address, balanceId), 
  { onSuccess: async () => queryClient.invalidateQueries("walletBalances"), onError: async (error) => Swal.fire(error.response.data.errors[0].description) });
}

//Function to delete WalletBalance
const deleteWalletBalance = (address, balanceId) => axios.delete(`${API_URL}/wallets/${address}/balances/${balanceId}`).then(response => response.data);
export function useDeleteWalletBalance() {
  const queryClient = useQueryClient()
  return useMutation(({ address, balanceId }) => deleteWalletBalance(address, balanceId), 
    { onSuccess: async () => queryClient.invalidateQueries("walletBalances"), onError: async (error) => Swal.fire(error.response.data.errors[0].description) });
}
// Message object inserted in delete message
const deleteMessageWalletBalance = {
  title: "Are you sure you want to delete this Wallet Balance?", 
  text: "You won't be able to revert this!", 
  icon: "warning", 
  showCancelButton: true, 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Yes, delete it!"
}

export const confirmationAlertDeleteWalletBalance = (event, address, balanceId, mutation) => {
  event.preventDefault();
  Swal.fire(deleteMessageWalletBalance).then(result => result.isConfirmed && mutation.mutate({ address: address, balanceId: balanceId }) );
};

export function useBlockchainNetworkCreateOrUpdate(){
  const { axiosInstance } = useAuth();
  const queryClient = useQueryClient();
  const walletApi = new WalletApi(undefined, API_URL, axiosInstance);
  return useMutation(({id, chain}) => id ? walletApi.networksBlockchainNetworkIdPut(id, chain) : walletApi.networksPost(chain),
    {onSuccess: () => queryClient.invalidateQueries("blockchainNetworks")});
}

export function useBlockchainNetworksList(){
  const { axiosInstance } = useAuth();
  const walletApi = new WalletApi(undefined, API_URL, axiosInstance);
  return useQuery("blockchainNetworks", ()=>walletApi.networksGet(0, 100));
}

export function useBlockchainNetworkDetails(id){
  const { axiosInstance } = useAuth();
  const walletApi = new WalletApi(undefined, API_URL, axiosInstance);
  return useQuery(["blockchainNetworks", id], () => walletApi.networksBlockchainNetworkIdGet(id));
}

// Message object inserted in delete message
const deleteBlockchainNetwork = {
  title: "Are you sure you want to delete this Blockchain network?",
  text: "You won't be able to revert this!",
  icon: "warning",
  showCancelButton: true,
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33",
  confirmButtonText: "Yes, delete it!"
}

export const confirmationAlertDeleteBlockchainNetwork = (event, networkId, mutation) => {
  event.preventDefault();
  Swal.fire(deleteBlockchainNetwork).then(result => result.isConfirmed && mutation.mutate({ id:networkId }) );
};

export function useBlockchainNetworkDelete(){
  const {axiosInstance} = useAuth();
  const walletApi = new WalletApi(undefined, API_URL, axiosInstance);
  const queryClient = useQueryClient();
  return useMutation(
    ({id})=>walletApi.networksBlockchainNetworkIdDelete(id), {
      onSuccess: () => queryClient.invalidateQueries("blockchainNetworks")
    }
  );
}