import axios from "axios";
import { useQuery, useQueryClient, useMutation } from "react-query";
import Swal from "sweetalert2";
import { API_URL } from "../../settings";
import { bookingPerioddateFormat, simpleSuccessfulMessage } from "../../Helpers/Helpers";

// Function to get all funds
const fetchFunds = page => axios.get(`${API_URL}/funds`, { params: { page: page }}).then(res => res.data);
export default function useFunds(page) {
  return useQuery(["funds", page], () => fetchFunds(page));
}

// Function to get all funds filtered by Fund ID, removing the selected fund from list (Funds Holding Form Component)
const filteredFunds = fundId => axios.get(`${API_URL}/funds`).then(res => res.data.items.filter(fund => fund.id !== fundId));
export const useFilteredFunds = fundId => useQuery(["funds", fundId], () => filteredFunds(fundId), {enabled: fundId !== undefined });

// Function to get detail of one fund
const fetchFund = (fundId) =>
  axios.get(`${API_URL}/funds/${fundId}`).then((response) => response.data);

export function useFund(fundId) {
  return useQuery(["fund", fundId], () => fetchFund(fundId), {enabled: fundId !== undefined });
}

// Function to get all fund classes list, by fundId 
const fetchFundAllClasses = fundId => axios.get(`${API_URL}/funds/${fundId}/classes/all`).then(res => res.data);
export const useFundAllClasses = fundId => useQuery(["fundClasses", fundId], () => fetchFundAllClasses(fundId), {enabled: fundId !== undefined });

// Function to get all funds owners
const fetchFundsOwners = () =>
  axios.get(`${API_URL}/funds/owner`).then((response) => response.data);

export function useFundsOwners() {
  return useQuery("fundsOwners", fetchFundsOwners);
}

// Function to get all Categories of one Fund by its ID
const fetchFundCategories = (fundId) => axios.get(`${API_URL}/funds/${fundId}/categories`).then(response => response.data);
export function useFundCategories(fundId) {
  return useQuery(["fundCategories", fundId], () => fetchFundCategories(fundId));
}

const fetchFundCategoryWeights = (fundId, includeAllHoldings) => axios.get(`${API_URL}/funds/${fundId}/weights?includeAllHoldings=${includeAllHoldings}`).then(response => response.data);

export function useFundCategoryWeights(fundId, includeAllHoldings){
  return useQuery(["fundCategoryWeights", fundId, includeAllHoldings], () => fetchFundCategoryWeights(fundId, includeAllHoldings));
}

const deleteAllFundCategoryCryptoWeights = (fundId, categoryId) => axios.delete(`${API_URL}/funds/${fundId}/weights/${categoryId}`);
export function useDeleteAllFundCategoryCryptoWeights(){
  const queryClient = useQueryClient();
  return useMutation(({fundId, categoryId}) => deleteAllFundCategoryCryptoWeights(fundId, categoryId), {
    onSuccess: () => queryClient.invalidateQueries("fundCategoryWeights")
  });
}

const addFundCategoryCryptosBulk = (cryptoWeights) => axios.post(`${API_URL}/funds/weights/bulk`, cryptoWeights).then(res => res.data);

export function useAddFundCategoryCryptosBulk(){
  const queryClient = useQueryClient();
  return useMutation((weights) => addFundCategoryCryptosBulk(weights), {
    onSuccess: () => queryClient.invalidateQueries("fundCategoryWeights")
  });
}


const updateFundCategoryAndCryptocurrencies = (input) => axios.put(`${API_URL}/funds/categories/bulkupdate`, input).then(res => res.data);
export function useUpdateFundCategoryAndCryptocurrencies(){
  const queryClient = useQueryClient();
  return useMutation((input) => updateFundCategoryAndCryptocurrencies(input), {
    onSuccess: (data) => queryClient.invalidateQueries("fundCategoryWeights")
  })
}

// Function to get all Holdings of one Fund by its ID
const fetchFundHoldings = (fundId, filterUnused) =>
  axios
    .get(`${API_URL}/funds/${fundId}/holdings?filterUnused=${filterUnused}`)
    .then((response) => response.data);

export function useFundHoldings(fundId, filterUnused) {
  return useQuery(["fundHoldings", fundId, filterUnused], () => fetchFundHoldings(fundId, filterUnused));
}

// Function to get Fund holdings by booking period
const fetchFundHoldingsByBookingPeriod = (fundId, filterUnused, bookingPeriod) =>
  axios
    .get(`${API_URL}/funds/${fundId}/holdings/history?fromBookingPeriod=${bookingPeriod}&toBookingPeriod=${bookingPeriod}&filterUnused=${filterUnused}`)
    .then(res => res.data);

export function useFundHoldingsByBookingPeriod(fundId, filterUnused, bookingPeriod) {
  return useQuery(["fundHoldings", fundId, filterUnused, bookingPeriod], () => fetchFundHoldingsByBookingPeriod(fundId, filterUnused, bookingPeriod));
}

// Function to get Holdings between dates
const fetchFundHoldingsByDates = (fundId, initialDate, endDate, sortProp, queryString, lowBalance, selectedFundClassId) =>
  axios.get(`${API_URL}/funds/${selectedFundClassId ? selectedFundClassId : fundId}/holdings/history?fromBookingPeriod=${initialDate}&toBookingPeriod=${endDate}&filterUnused=true`).then(res => {
    if (lowBalance) {
      const lowBalanceList = res.data.filter(data => data.endUSDValue <= 1 && data.endUSDValue >= -1)
      if (queryString) {
        return lowBalanceList.filter(data => data.displayName.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
      return lowBalanceList
    }

    if (sortProp) {
      const sortedList = sortProp.type === "desc" ? res.data.sort((a, b) => b[sortProp.value] - a[sortProp.value]) : res.data.sort((a, b) => a[sortProp.value] - b[sortProp.value])
      if (queryString) {
        return sortedList.filter(data => data.displayName.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
      return sortedList
    }
    
    if (queryString) {
      return res.data.filter(data => data.displayName.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
    }
    return res.data
  });

export function useFundHoldingsByDates(fundId, initialDate, endDate, sortProp, queryString, lowBalance, selectedFundClassId) {
  return useQuery(["fundHoldings", fundId, initialDate, endDate, sortProp, queryString, lowBalance, selectedFundClassId], () =>
    fetchFundHoldingsByDates(fundId, initialDate, endDate, sortProp, queryString, lowBalance, selectedFundClassId)
  );
}

// Function to get on Holding by its ID
const fetchFundHolding = (fundId, holdingId) => axios.get(`${API_URL}/funds/${fundId}/holdings/${holdingId}`).then(response => response.data);
const holdingIdCheck = id => (id === undefined) || (id === "1") ? false : true

/**
 * 
 * @param {string|null} fundId 
 * @param {string|null} holdingId 
 * @returns {import("react-query").UseQueryResult<import("@hodl.nl/hodltradingdesk").HoldingDetailViewModel, Error>}
 */
export function useFundHolding(fundId, holdingId) {
  return useQuery(
    ["fundHolding", fundId, holdingId], 
    () => fetchFundHolding(fundId, holdingId), {enabled: holdingIdCheck(holdingId) },
    {
      enabled: fundId != null && holdingId !== null
    }
  );
}

// Function to get Fund booking Periods
const fetchFundBookingPeriods = fundId => axios.get(`${API_URL}/funds/${fundId}/bookingperiods`).then(res => res.data)
export const useHoldingBookingPeriods = fundId => useQuery(["bookingPeriods", fundId], () => fetchFundBookingPeriods(fundId))

// Function to get Fund Classes by booking Period
const fetchFundClassesByBookingPeriod = (fundId, bookingPeriod) => axios.get(`${API_URL}/funds/${fundId}/classes`, { params: { bookingPeriod } }).then(res => res.data)
export const useFundClassesByBookingPeriod = (fundId, bookingPeriod) => useQuery(["fundClasses", fundId, bookingPeriod], () => fetchFundClassesByBookingPeriod(fundId, bookingPeriod), {enabled: bookingPeriod !== undefined })

// Function to display Month string in Bookin Period Select Input
export const getMonthString = date => {
  const months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
  const month = date.slice(4,6)
  const filteredMonth = parseInt(month)
  return months[filteredMonth] + " " + date.slice(0,4)
}

// Function to fetch all Layers that belong to a Fund by its ID
const fetchlayers = fundId => axios.get(`${API_URL}/funds/${fundId}/layers`).then(response => response.data)
export function useFundLayers(fundId) {
  return useQuery(["layers", fundId], () => fetchlayers(fundId))
}

// Function to fetch one Layer that belongs to a Fund by its ID
const fetchFundLayer = async (fundId, layerIndex) => await axios.get(`${API_URL}/funds/${fundId}/layers`).then(response => response.data.find(layer => layer.layerIndex === parseInt(layerIndex)))
export function useFundLayer(fundId, layerIndex) {
  return useQuery(["layer", fundId, layerIndex], () => fetchFundLayer(fundId, layerIndex), { enabled: layerIndex !== undefined })
}

// Function to get funds list for Fund Detail Component Title Dropdown
const fetchFundsList = fundId => axios.get(`${API_URL}/funds`).then(res => res.data.items.filter(fund => fund.id !== fundId));
export const useFundsList = fundId => useQuery(["fundsList", fundId], () => fetchFundsList(fundId), {enabled: fundId !== undefined });

// Function to get Fund Nav by date
const fetchNavbyPeriod = (fundId, period) => axios.get(`${API_URL}/funds/${fundId}/periodnavs?from=${period}&to=${period}`).then(res => res.data)
export const useFetchNavByPeriod = (fundId, period) => useQuery(["fundNavs", fundId, period], () => fetchNavbyPeriod(fundId, period), { enabled: period !== undefined })

// Function to get holdings for comparer modal at Fund's dashboard
const fetchFundHoldingsForComparerModal = async (fundId, initialDate, endDate, selectedFundClassId, payload) => {
  return await axios.get(`${API_URL}/funds/${selectedFundClassId ? selectedFundClassId : fundId}/holdings/history?fromBookingPeriod=${initialDate}&toBookingPeriod=${endDate}&filterUnused=true`).then(res => {
    const rows = payload.split('\n'); // Split by newline to get rows
    const headers = rows[0].split('\t').filter(header => header !== "").map(x => x.trim()); // Get headers as an array
    const data = rows.slice(1).map(row => {
        const rowData = row.split('\t').filter(rowLine => rowLine !== "").map(x => x.trim()); // Split each row into columns
        const obj = {};
        headers.forEach((header, index) => {
            if (header && rowData[index]) {
                obj[header.trim().toLowerCase().replace(" ", "")] = rowData[index].trim().replace(" ", ""); // Map each column to header key
            }
        });
        return obj;
    });

    const comparerTableFiltered = res.data.map(holding => {
        const pair = data.find(dataHolding => dataHolding.ticker === holding.symbol)
        if (pair) {
          // if there is a match between the holdings on the DB and the excel file, return the holding with the excel pair, and a boolean for conditional rendering in ComparerModal table
          return { ...holding, pair, excelPair: true }
        } else {
          // if there is NO match, then return the holding and a default value of pair, with amount zero as a string and, since it doesnt have and excel pai, set this property as false
          return { ...holding, pair: { amount: "0", excelPair: false }}
        }
    })
    
    return comparerTableFiltered
  });
}

export function useFundHoldingsForComparerModal(fundId, initialDate, endDate, selectedFundClassId, payload) {
  return useQuery(["fundHoldingsCompared", fundId, initialDate, endDate, selectedFundClassId, payload], () => fetchFundHoldingsForComparerModal(fundId, initialDate, endDate, selectedFundClassId, payload), {enabled: payload !== undefined });
}


// MUTATIONS

//Function to post a Fund
const postFund = fund => axios.post(`${API_URL}/funds`, fund).then(res => res.data);
export function useCreateFund() {
  const queryClient = useQueryClient();
  return useMutation((fund) => postFund(fund), { onSuccess: () => {
    simpleSuccessfulMessage("Fund created Succesfully")
    queryClient.invalidateQueries("funds")
  }});
}

//Function to edit Fund
const editFund = (fund, fundId) =>
  axios.put(`${API_URL}/funds/${fundId}`, fund).then((response) => response.data);

export function useEditFund() {
  const queryClient = useQueryClient();
  return useMutation(({ fund, fundId }) => editFund(fund, fundId), {
    onSuccess: () => {
      queryClient.invalidateQueries("funds")
      simpleSuccessfulMessage("Fund edited succesfully")
    },
  });
}

//Function to delete Fund
const deleteFund = (id) => axios.delete(`${API_URL}/funds/${id}`).then(response => response.data);
export function useDeleteFund() {
  const queryClient = useQueryClient()
  return useMutation(({ id }) => deleteFund(id), 
    { onSuccess: async () => {
        queryClient.invalidateQueries("funds") 
        simpleSuccessfulMessage("Fund deleted Succesfully")
      }
    } 
  );
}
// Message object inserted in delete message
const deleteMessageObject = {
  title: "Are you sure you want to delete this?", 
  text: "You won't be able to revert this!", 
  icon: "warning", 
  showCancelButton: true, 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Yes, delete it!"
}

export const confirmationAlertDeleteFund = (event, id, mutation) => {
  event.preventDefault();
  event.stopPropagation()
  Swal.fire(deleteMessageObject).then((result) => result.isConfirmed && mutation.mutate({ id: id }) );
};

// Get UTC TimeStamp function
export const getUTCTime = (inputDate) => {
  const enteredDate = new Date(inputDate);
  return enteredDate.toISOString();
};

//Function to add a Category to a Fund
const addCategoryToFund = (category, fundId) => axios.post(`${API_URL}/funds/${fundId}/categories`, category).then((response) => response.data);
export function useAddCategoryToFund() {
  const queryClient = useQueryClient();
  return useMutation(({ category, fundId }) => addCategoryToFund(category, fundId),
    { onSuccess: async () => queryClient.invalidateQueries("fundCategories") }
  );
}

// Function to delete Fund Category
export const deleteFundCategory = (categoryId, fundId) => axios.delete(`${API_URL}/funds/${fundId}/categories/${categoryId}`);
export function useDeleteFundCategory() {
  const queryClient = useQueryClient()
  return useMutation(({ categoryId, fundId }) => deleteFundCategory(categoryId, fundId), 
    {
      onSuccess: async () => queryClient.invalidateQueries("fundCategories") ,
      onError: ()=>{}
    }
  );
}
export const confirmationAlertDeleteFundCategory = (event, categoryId, fundId, mutation) => {
  event.preventDefault();
  Swal.fire(deleteMessageObject).then((result) => result.isConfirmed && mutation.mutate({ categoryId: categoryId, fundId: fundId }) );
};


//Function to create a Fund Holding
const createFundHolding = (holding, fundId) =>
  axios.post(`${API_URL}/funds/${fundId}/holdings`, holding).then((response) => response.data);

export function useCreateFundHolding() {
  const queryClient = useQueryClient();
  return useMutation(({ holding, fundId }) => createFundHolding(holding, fundId),
    { onSuccess: () => {
        queryClient.invalidateQueries("fundHoldings")
        queryClient.invalidateQueries("bookingPeriods")
      }
    })
}

//Function to edit a Fund Holding
const editFundHolding = (fundId, holdingId, holdingEdit) => axios.put(`${API_URL}/funds/${fundId}/holdings/${holdingId}`, holdingEdit).then(response => response.data);
export function useEditFundHolding() {
  const queryClient = useQueryClient();
  return useMutation(({ fundId, holdingId, holdingEdit }) => editFundHolding(fundId, holdingId, holdingEdit),
    { onSuccess: () => queryClient.invalidateQueries("fundHolding")});
}

//Function to create a Fund Layer
const createFundLayer = (layer, fundId) => axios.post(`${API_URL}/funds/${fundId}/layers`, layer).then((response) => response.data);
export function useCreateFundLayer() {
  const queryClient = useQueryClient();
  return useMutation(({ layer, fundId }) => createFundLayer(layer, fundId), {
    onSuccess: () => queryClient.invalidateQueries("funds")});
}

//Function to edit a Fund Layer
const editFundLayer = (layer, fundId, layerIndex) => axios.put(`${API_URL}/funds/${fundId}/layers/${layerIndex}`, layer).then((response) => response.data);
export function useEditFundLayer() {
  const queryClient = useQueryClient();
  return useMutation(({ layer, fundId, layerIndex }) => editFundLayer(layer, fundId, layerIndex), {
    onSuccess: () => queryClient.invalidateQueries("funds")});
}

// Function to delete Fund Layer
export const deleteLayer = (layerIndex, fundId) => axios.delete(`${API_URL}/funds/${fundId}/layers/${layerIndex}`).then(response => response.data);
export function useDeleteFundLayer() {
  const queryClient = useQueryClient()
  return useMutation(({ layerIndex, fundId }) => deleteLayer(layerIndex, fundId), { 
      onSuccess: () => queryClient.invalidateQueries("fund")});
}

export const confirmationAlertDeleteFundLayer = (event, layerIndex, fundId, mutation) => {
  event.preventDefault();
  Swal.fire(deleteFundHoldingMessageObject).then(result => result.isConfirmed && mutation.mutate({ layerIndex: layerIndex, fundId: fundId  }) );
};


//Function to post a Fund Owner
const postFundOwner = (fundOwner) =>
  axios.post(`${API_URL}/funds/owner`, fundOwner).then((response) => response.data);

export function useCreatefundOwner() {
  const queryClient = useQueryClient();
  return useMutation((fundOwner) => postFundOwner(fundOwner), {
    onSuccess: () => queryClient.invalidateQueries("fundsOwners"),
  });
}

//Function to delete Fund Holding
const deleteFundHolding = (fundId, holdingId) => axios.delete(`${API_URL}/funds/${fundId}/holdings/${holdingId}`).then(response => response.data);
export function useDeleteFundHolding() {
  const queryClient = useQueryClient()
  return useMutation(({ fundId, holdingId }) => deleteFundHolding(fundId, holdingId), 
    { onSuccess: async () => {
        queryClient.invalidateQueries("fundHoldings")
        queryClient.invalidateQueries("bookingPeriods")
      }
    }) 
}

// Message object inserted in delete message
const deleteFundHoldingMessageObject = {
  title: "Are you sure you want to delete this?", 
  text: "You won't be able to revert this!", 
  icon: "warning", 
  showCancelButton: true, 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Yes, delete it!"
}

export const confirmationAlertDeleteFundHolding = (event, fundId, holdingId, mutation) => {
  event.preventDefault();
  Swal.fire(deleteFundHoldingMessageObject).then((result) => result.isConfirmed && mutation.mutate({ fundId: fundId, holdingId: holdingId }) );
};

// Functions to get holding dates
// export const getInitialYearMonth = () => {
//   const d = new Date();
//   const year = d.getUTCFullYear().toString();
//   const month = d.getMonth() + 1
//   month.toString()
//   const formattedMonth = (month) => (month > 9 ? month : `0${month}`);
//   const formatDate = year + formattedMonth(month);
//   return formatDate
// };

//Function to Close Fund History
const closeHistory = async (fundId) => {
  return await axios.post(`${API_URL}/funds/${fundId}/closehistory`, fundId).then(response => response.data)
};
export function useCloseHistory() {
  const queryClient = useQueryClient();
  return useMutation(({ fundId }) => closeHistory(fundId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["fund", "fundHoldings"])
      } 
    }
  );
}

//Function to Close Fund Period
const closePeriod = async (fundId, period) => await axios.post(`${API_URL}/funds/${fundId}/closeperiod`, period).then(response => response.data)
export function useClosePeriod() {
  const queryClient = useQueryClient();
  return useMutation(({ fundId, period }) => closePeriod(fundId, period),
    { onSuccess: () => { queryClient.invalidateQueries(["fund", "fundHoldings"]) } }
  )
}

// Function to fetch close period date for default date in closePeriodMessage pop up
const fetchCloseDate = async (fundId, bookingPeriod) => await axios.get(`${API_URL}/funds/${fundId}/${bookingPeriod}/closedate`).then(response => response.data)
export function useCloseDate(fundId, bookingPeriod) {
  return useQuery(["closeDate", fundId, bookingPeriod], () => fetchCloseDate(fundId, bookingPeriod), {enabled: bookingPeriod !== undefined })
}

// Message object inserted in Close Period 
const closePeriodMessageObject = (bookingPeriod, recalculate, managementFeeFrequency, closeDate) => {
  if (managementFeeFrequency && managementFeeFrequency === 365) {
    const defaultDate = closeDate ? new Date(closeDate) : new Date()
    return ({
      title: `Are you sure you want to ${recalculate ? 'recalculate' : 'close'} this Booking Period ${bookingPerioddateFormat(bookingPeriod)}?`, 
      icon: "warning", 
      showCancelButton: true, 
      confirmButtonColor: "#6d9e93",
      cancelButtonColor: "#d33", 
      confirmButtonText: "Yes, Close this Booking Period",
      input: "date",
      inputValue: defaultDate.toISOString().split('T')[0]
    })
  }
  
  return ({
    title: `Are you sure you want to ${recalculate ? 'recalculate' : 'close'} this Booking Period ${bookingPerioddateFormat(bookingPeriod)}?`, 
    icon: "warning", 
    showCancelButton: true, 
    confirmButtonColor: "#6d9e93",
    cancelButtonColor: "#d33", 
    confirmButtonText: "Yes, Close this Booking Period"
  })
}

export const confirmationClosePeriod = (event, fundId, bookingPeriod, mutation, recalculate, managementFeeFrequency, closeDate) => {
  event.preventDefault();
  Swal.fire(closePeriodMessageObject(bookingPeriod, recalculate, managementFeeFrequency, closeDate)).then((result) => {
    if (result.isConfirmed) {
      if (typeof result.value === "string") {
        const selectedCloseDate = new Date(result.value)
        return mutation.mutate({ fundId: fundId, period: { period: bookingPeriod, recalculate: recalculate, closeDate: selectedCloseDate.toISOString() } }) 
      }
      return mutation.mutate({ fundId: fundId, period: { period: bookingPeriod, recalculate: recalculate } }) 
    }
  });
};

//Function to Recalculate Current Active Period
const recalculatePeriod = async (fundId) => axios.post(`${API_URL}/funds/${fundId}/holdings/recalc`).then(response => response.data)
export function useRecalculatePeriod() {
  const queryClient = useQueryClient();
  return useMutation(({ fundId }) => recalculatePeriod(fundId),
    { onSuccess: () => { queryClient.invalidateQueries("fundHoldings") } }
  )
}

// Message object inserted in delete message
const recalculateActivePeriodMessageObject = {
  title: "Are you sure you want to recalculate the Active Period?", 
  text: "You won't be able to revert this!", 
  icon: "warning", 
  showCancelButton: true, 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Yes, Recalculate!"
}

export const confirmationRecalculateActivePeriod = (e, fundId, mutation) => {
  e.preventDefault()
  Swal.fire(recalculateActivePeriodMessageObject).then(result => result.isConfirmed && mutation.mutate({ fundId }))
} 

//Function to Rollback Closed Booking Period
const rollbackClosePeriod = async (fundId) => await axios.post(`${API_URL}/funds/${fundId}/rollback_closeperiod`, fundId).then(response => response.data)
export function useRollbackPeriod() {
  const queryClient = useQueryClient();
  return useMutation(({ fundId }) => rollbackClosePeriod(fundId),
    { onSuccess: () => queryClient.invalidateQueries(["fund", "fundHoldings"]) } 
  );
}

// Message object inserted in rollback message
const rollbackMessageObject = bookingPeriods => {
  if (bookingPeriods.length < 2) {
    return (
      {
        title: 'There are no Booking Periods available to rollback', 
        icon: "error", 
        showCancelButton: true,
        showConfirmButton: false, 
        cancelButtonColor: "#CD5C5C", 
        cancelButtonText: "Ok"
      }
    )
  }
  
  const closingBookingPeriod = bookingPeriods[1]
  return (
    {
      title: `Do you want to proceed with the data rollback for period ${bookingPerioddateFormat(closingBookingPeriod)}?`, 
      icon: "warning", 
      showCancelButton: true, 
      confirmButtonColor: "#6d9e93",
      cancelButtonColor: "#CD5C5C", 
      confirmButtonText: "Yes, proceed with Rollback!"
    }
  )
}

export const confirmationAlertRollback = (event, fundId, mutation, bookingPeriod) => {
  event.preventDefault();
  Swal.fire(rollbackMessageObject(bookingPeriod)).then((result) => result.isConfirmed && mutation.mutate({ fundId: fundId }) );
};

// NAV validation
const validateNAV = async (fundId, bookingPeriod) => await axios.post(`${API_URL}/funds/${fundId}/${bookingPeriod}/validate`).then(response => response.data)
export function useValidateNAV() {
  const queryClient = useQueryClient();
  return useMutation(({ fundId, bookingPeriod }) => validateNAV(fundId, bookingPeriod),
    { onSuccess: () => 
      {
        queryClient.invalidateQueries(["fund"])
        queryClient.invalidateQueries(["fundNavs"])
        simpleSuccessfulMessage("NAV validated successfully")} 
    }
  );
}

// Message object inserted in validate NAV message
const validateNAVMessageObject = (bookingPeriod) => {
  return (
    {
      title: `Are you sure you want to set the NAV for booking period ${bookingPerioddateFormat(bookingPeriod)} as validated?`, 
      text: "The NAV will be published and used for follow up calculations. It can not be reverted!", 
      icon: "warning", 
      showCancelButton: true, 
      confirmButtonColor: "#6d9e93",
      cancelButtonColor: "#d33", 
      confirmButtonText: "Yes, set validated!"
    }
  )
}

export const confirmationValidateNAV = (event, fundId, mutation, bookingPeriod) => {
  event.preventDefault();
  Swal.fire(validateNAVMessageObject(bookingPeriod)).then((result) => result.isConfirmed && mutation.mutate({ fundId: fundId, bookingPeriod: bookingPeriod }) );
};

// Function to create Daily NAV
const createNAV = async (fundId, payload) => await axios.post(`${API_URL}/funds/${fundId}/calcperiodnavs`, payload).then(res => res.data)
export const useCreateNAV = () => useMutation(({ fundId, payload }) => createNAV(fundId, payload), { onSuccess: () => simpleSuccessfulMessage("NAV created successfully")});


// Function to create new Fund Class
const createFundClass = (fundId, payload) => axios.post(`${API_URL}/funds/${fundId}/classes`, payload).then(res => res.data)
export const useCreateFundClass = () => {
  const queryClient = useQueryClient();
  return useMutation(({ fundId, payload }) => createFundClass(fundId, payload), { 
    onSuccess: () => {
      queryClient.invalidateQueries("fund")
      simpleSuccessfulMessage("Fund Class created succesfully")
    }
  })
}

//Function to edit a Fund Class
const editFundClass = (fundId, fundClassId, payload) => axios.put(`${API_URL}/funds/${fundId}/classes/${fundClassId}`, payload).then(res => res.data);
export function useEditFundClass() {
  const queryClient = useQueryClient();
  return useMutation(({ fundId, fundClassId, payload }) => editFundClass(fundId, fundClassId, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries("fund")
      simpleSuccessfulMessage("Fund Class edited succesfully")
    }
})
}

//Function to delete Fund Class
const deleteFundClass = (fundId, fundClassId) => axios.delete(`${API_URL}/funds/${fundId}/classes/${fundClassId}`).then(res => res.data);
export const useDeleteFundClass = () => {
  const queryClient = useQueryClient()
  return useMutation(({ fundId, fundClassId }) => deleteFundClass(fundId, fundClassId), 
    { onSuccess: async () => {
        queryClient.invalidateQueries("fund")
        simpleSuccessfulMessage("Fund Class removed succesfully")
      }
    }) 
}

export const confirmationAlertDeleteFundClass = (event, fundId, fundClassId, mutation) => {
  event.preventDefault();
  Swal.fire(deleteFundHoldingMessageObject).then((result) => result.isConfirmed && mutation.mutate({ fundId, fundClassId }) );
};

// Function to Import Navs
const importNavs = (fundId, payload) => axios.post(`${API_URL}/funds/${fundId}/periodnavs/import`, payload, { headers: {'Content-Type': 'application/json'}}).then(res => res.data)
export const useImportNavs = () => {
  const queryClient = useQueryClient()
  return useMutation(({ fundId, payload }) => importNavs(fundId, payload), {
    onSuccess: () => queryClient.invalidateQueries("navs")
  }) 
}

// Function to Merge classes
const mergeFundClasses = (fundId, payload) => axios.post(`${API_URL}/funds/${fundId}/classes/merge`, payload).then(res => res.data)
export const useMergeFundClasses = () => {
  const queryClient = useQueryClient();
  return useMutation(({ fundId, payload }) => mergeFundClasses(fundId, payload), { 
    onSuccess: () => {
      queryClient.invalidateQueries("transfers")
      queryClient.invalidateQueries("fundHoldings")
      queryClient.invalidateQueries("fundClasses")
      simpleSuccessfulMessage("Classes merged succesfully")
    }
  })
}

export const confirmationAlertMergeClasses = (fundId, mergeFundClass, intoFundClassId, classes, mutation) => {
  Swal.fire(mergeMessageObject(mergeFundClass, intoFundClassId, classes))
    .then((result) => result.isConfirmed && mutation.mutate({ fundId, payload: { mergeFundClassId: mergeFundClass.id, intoFundClassId: intoFundClassId } }) );
};

// Message object inserted in delete message
const mergeMessageObject = (mergeFundClass, intoFundClassId, classes) => {
  return ({
    title: `Are you sure you want to merge ${mergeFundClass.className} into ${classes.find(fundClass => fundClass.id === intoFundClassId).className}`, 
    text: "You won't be able to revert this!", 
    icon: "warning", 
    showCancelButton: true, 
    confirmButtonColor: "#6d9e93",
    cancelButtonColor: "#d33", 
    confirmButtonText: "Yes, merge the classes"
  })
}
