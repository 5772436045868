import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import Swal from "sweetalert2";
import { useAuth } from "../../Hooks/useAuth";
import {
  BalanceReconciliationApi,
  ReconciliationListViewModel,
} from "@hodl.nl/hodltradingdesk";
import { API_URL } from "../../settings";
import { AxiosResponse } from "axios";

export function useBalanceReconciliation(
  fundId: string
): UseQueryResult<AxiosResponse<ReconciliationListViewModel[], any>> {
  const { axiosInstance } = useAuth();
  return useQuery(
    ["balance-reconciliation", fundId],
    () => {
      return new BalanceReconciliationApi(
        undefined,
        API_URL,
        axiosInstance
      ).balanceReconciliationOutOfLineGet(fundId);
    },
    { enabled: fundId != null }
  );
}

interface ManualBankBalanceMutationProps {
  bankAccountId: string;
  currencyIsoCode: string;
  newBalance: string;
}

export function useManualBankAccountBalanceUpdate() {
  const queryClient = useQueryClient();
  const { axiosInstance } = useAuth();
  return useMutation(
    async ({
      bankAccountId,
      currencyIsoCode,
      newBalance,
    }: ManualBankBalanceMutationProps) => {
      await new BalanceReconciliationApi(
        undefined,
        API_URL,
        axiosInstance
      ).balanceReconciliationOutOfLineBankbalancePost(
        bankAccountId,
        currencyIsoCode,
        newBalance
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("balance-reconciliation");
      },
    }
  );
}

export const confirmAndUpdateBankBalance = (
  details: ManualBankBalanceMutationProps,
  mutation: UseMutationResult<
    void,
    unknown,
    ManualBankBalanceMutationProps,
    unknown
  >
) => {
  Swal.fire({
    title: "Are you sure?",
    text: "Do you want to update the bank account balance?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#6d9e93",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, update it!",
  }).then((result) => {
    if (result.isConfirmed) {
      mutation.mutate(details);
    }
  });
};

interface ManualWalletBalanceMutationProps {
  walletAddress: string;
  cryptoCurrencyId: string;
  newBalance: string;
  blockchainNetworkId?: string;
}
export function useManualWalletBalanceUpdate() {
  const queryClient = useQueryClient();
  const { axiosInstance } = useAuth();
  return useMutation(
    async ({
      walletAddress,
      cryptoCurrencyId,
      blockchainNetworkId,
      newBalance,
    }: ManualWalletBalanceMutationProps) => {
      await new BalanceReconciliationApi(
        undefined,
        API_URL,
        axiosInstance
      ).balanceReconciliationOutOfLineWalletbalancePost(
        walletAddress,
        cryptoCurrencyId,
        newBalance,
        blockchainNetworkId
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("balance-reconciliation");
      },
    }
  );
}

export const confirmAndUpdateBalance = (
  details: ManualWalletBalanceMutationProps,
  mutation: UseMutationResult<
    void,
    unknown,
    ManualWalletBalanceMutationProps,
    unknown
  >
) => {
  Swal.fire({
    title: "Are you sure?",
    text: "Do you want to update the wallet balance?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#6d9e93",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, update it!",
  }).then((result) => {
    if (result.isConfirmed) {
      mutation.mutate(details);
    }
  });
};
