import React, { useState, useEffect } from 'react'
import styles from "./NavigationBar.module.scss"
import { useAuth } from '../../Hooks/useAuth'
import { Link, useLocation } from "react-router-dom";
import useFunds from '../../query-hooks/fund-query-hooks/useFund';

const SidebarDisplay = ({ item, displaySidebar }) => {
    const { pathname } = useLocation()
    const { darkmode } = useAuth()
    const barStyle = { backgroundColor: "#6d9e93", borderRadius: "15px", opacity: "0.8" }
    const barStyleonToggle = { backgroundColor: "#6d9e93", borderRadius: "0 10px 10px 0", opacity: "0.8"}
    const funds = useFunds()
    const [open, setOpen] = useState(false); // State to open/close Collapsible Window with menu sub-items
    const [ subcontainerOnToggle, setSubcontainerOnToggle ] = useState(false)

    useEffect(() => {
        if (item.subcontainer && pathname.includes(item.subcontainer)) return setOpen(true)
    }, [item.subcontainer, pathname])

    if (displaySidebar) {
        return (
            <div>
                <div className={styles.sideNavLinks} style={pathname.includes(item.title.toLowerCase()) ? barStyle : {} }> 
                    <item.name className={pathname.includes(item.title.toLowerCase()) ? styles.iconsDark : styles.icons}/>
                    <Link 
                        className={darkmode ? styles.sideLinksDark : styles.sideLinks} to={item.to} style={pathname.includes(item.title.toLowerCase()) ? {color: "white"} : {} } 
                        >{item.title}
                    </Link>
                    {/* { item.subcontainer && <MdKeyboardArrowDown className={styles.subcontainerIcon} onClick={() => setOpen(!open)} aria-expanded={open} /> } */}
                </div>
                {/* <Collapse in={open}>
                    <div>
                        { ( item.subcontainer === "funds" &&  funds.isSuccess) && funds.data.items.map(fund => 
                            <Link 
                                key={fund.id} 
                                className={styles.subLinkDark} 
                                style={pathname.includes(fund.id) ? { color:"#6D9E93", textDecoration:"underline", fontWeight:"900" } : {} }
                                to={`/main/funds/fund-detail/${fund.id}`}
                            >{ fund.fundName }
                            </Link>
                        )}
                    </div>
                </Collapse>  */}
            </div>
        )
    }

    // Toggle Mode Sidebar
    return (
        <div style={pathname.includes(item.title.toLowerCase()) ? barStyleonToggle : {} }> 
            <div className={styles.tooltip} style={{marginTop:".7rem", position:"relative"}}>
                <Link className={darkmode ? styles.sideLinksDark : styles.sideLinks} to={item.to} style={pathname.includes(item.title.toLowerCase()) ? {color: "white"} : {} } >
                    <item.name className={pathname.includes(item.title.toLowerCase()) ? styles.iconsDark : styles.icons}/>
                    {/* {   item.subcontainer && 
                            <button 
                                className={styles.toggleSubcontainerButton} 
                                onClick={() => setSubcontainerOnToggle(!subcontainerOnToggle)}
                            ><MdKeyboardArrowRight />
                            </button> 
                    } */}
                    <span className={styles.tooltiptext}>{item.title}</span>
                </Link>
                {/* {
                    subcontainerOnToggle && 
                        <div className={styles.subcontainerOnToggle}>
                            { ( item.subcontainer === "funds" &&  funds.isSuccess) && funds.data.items.map(fund => 
                            <Link 
                                key={fund.id} 
                                className={styles.subLinkDark} 
                                style={pathname.includes(fund.id) ? { color:"#F6F3EE", textDecoration:"underline", fontWeight:"900" } : {} }
                                to={`/main/funds/fund-detail/${fund.id}`}
                            >{ fund.fundName }
                            </Link>
                            )}
                        </div>
                } */}
            </div>
        </div>
    ) 


}
export default SidebarDisplay;