import React from 'react';
import { useBalanceReconciliation } from '../../../../query-hooks/balance-reconciliation-hooks/useBalanceReconciliation';
import {
    BankBalanceDetailViewModel,
    HoldingListViewModel,
    ReconciliationListViewModel,
    WalletBalanceIncludingExchangeAccountDetailViewModel
} from "@hodl.nl/hodltradingdesk";
import styles from './ReconciliationTable.module.scss';
import { FaSort, FaChevronDown, FaChevronRight } from "react-icons/fa";
import { useSortedList } from '../../../../Hooks/useSortedList';
import { ReconciliationDetails } from './ReconciliationDetails';
import { UpdateWalletBalanceRow } from './UpdateWalletBalanceRow';
import { UpdateBankBalanceRow } from './UpdateBankBalanceRow';
import { useAuth } from '../../../../Hooks/useAuth';
import { numberEditor, percentageEditor } from '../../../../Helpers/Helpers';

interface ReconciliationTableProps {
    fundId: string;
}

export class ExtendedHoldingReconciliationListViewModel implements ReconciliationListViewModel {
    constructor(input: ReconciliationListViewModel){
        this.holding = input.holding;
        this.walletBalances = input.walletBalances;
        this.bankAccountBalances = input.bankAccountBalances;
    }
    public holding?: HoldingListViewModel;
    public walletBalances?: Array<WalletBalanceIncludingExchangeAccountDetailViewModel> | null;
    public bankAccountBalances?: Array<BankBalanceDetailViewModel> | null;

    public get cryptoCurrencyId(){
        if(!!this.holding?.cryptoCurrency?.id) return this.holding.cryptoCurrency.id;
        if(!!this.walletBalances || this.walletBalances!.length === 0) return null;
        return this.walletBalances![0].cryptoCurrency?.id;
    }

    public get currencyIsoCode(){
        if(!!this.holding?.currencyISOCode) return this.holding.currencyISOCode;
        if(!!this.bankAccountBalances || this.bankAccountBalances!.length === 0) return null;
        return this.bankAccountBalances![0].currency!.isoCode;
    }
}

interface TableDataType {
    key: string;
    symbol: string;
    holdingBalance: number;
    measuredBalance: number;
    percentageDiff: number;
    absoluteDiff: number;
    usdDiff: number;
    originalData: ExtendedHoldingReconciliationListViewModel;
}

export function ReconciliationTable({ fundId }: ReconciliationTableProps) {
    const { data, isLoading } = useBalanceReconciliation(fundId);
    const [expandedRows, setExpandedRows] = React.useState<Set<string>>(new Set());
    const { darkmode } = useAuth();

    const tableData: TableDataType[] = React.useMemo(() => {
        if (!data?.data) return [];
        
        return data.data.map((item: ReconciliationListViewModel) => {
            const walletBalance = item.walletBalances?.reduce((a,b)=>a + (b.balance ?? 0), 0) || 0;
            const bankBalance = item.bankAccountBalances?.reduce((a,b)=>a + (b.balance ?? 0), 0)|| 0;
            const measuredBalance = walletBalance + bankBalance;
            const holdingBalance = item.holding?.endBalance || 0;
            
            const absoluteDiff = holdingBalance - measuredBalance;
            const percentageDiff = holdingBalance === 0 ? 
                (measuredBalance === 0 ? 0 : 100) : 
                ((measuredBalance - holdingBalance) / holdingBalance) * 100;

            const usdDiff = absoluteDiff * (item.holding?.endUSDPrice || 0);

            return {
                key: item.holding?.id ?? Math.random().toString(),
                symbol: item.holding?.symbol ?? item.walletBalances![0]?.cryptoCurrency?.symbol ?? item.bankAccountBalances![0]?.currency?.isoCode ?? 'N/A', 
                holdingBalance,
                measuredBalance,
                percentageDiff,
                absoluteDiff,
                usdDiff,
                originalData: new ExtendedHoldingReconciliationListViewModel(item),
            };
        }).filter(x => Math.abs(x.usdDiff) > 10);
    }, [data]);

    const { sortedData, handleSort, sortProp, sortOrder } = useSortedList<TableDataType>(tableData);

    const getSortIndicator = (field: string) => {
        if (sortProp !== field) return <FaSort style={{fontSize:"18px"}} />;
        return sortOrder === 'asc' ? '↑' : '↓';
    };

    const toggleRowExpansion = (key: string) => {
        setExpandedRows(prev => {
            const newSet = new Set(prev);
            if (newSet.has(key)) {
                newSet.delete(key);
            } else {
                newSet.add(key);
            }
            return newSet;
        });
    };

    if (isLoading) {
        return <div className={`${styles.cell} ${darkmode ? styles.dark : ''}`} style={{ textAlign: 'center' }}>Loading...</div>;
    }

    return (
        <div className={`${styles.container} ${darkmode ? styles.dark : ''}`}>
            <table className={`${styles.table} ${darkmode ? styles.dark : ''}`}>
                <thead className={`${styles.thead} ${darkmode ? styles.dark : ''}`}>
                    <tr>
                        <th className={`${styles.header} ${darkmode ? styles.dark : ''}`} onClick={() => handleSort('symbol')}>
                            Symbol {getSortIndicator('symbol')}
                        </th>
                        <th className={`${styles.header} ${darkmode ? styles.dark : ''}`} onClick={() => handleSort('holdingBalance')}>
                            Holding Balance {getSortIndicator('holdingBalance')}
                        </th>
                        <th className={`${styles.header} ${darkmode ? styles.dark : ''}`} onClick={() => handleSort('measuredBalance')}>
                            Total Measured {getSortIndicator('measuredBalance')}
                        </th>
                        <th className={`${styles.header} ${darkmode ? styles.dark : ''}`} onClick={() => handleSort('percentageDiff')}>
                            Percentage Diff {getSortIndicator('percentageDiff')}
                        </th>
                        <th className={`${styles.header} ${darkmode ? styles.dark : ''}`} onClick={() => handleSort('absoluteDiff')}>
                            Absolute Diff {getSortIndicator('absoluteDiff')}
                        </th>
                        <th className={`${styles.header} ${darkmode ? styles.dark : ''}`} onClick={() => handleSort('usdDiff')}>
                            USD Diff {getSortIndicator('usdDiff')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedData.length == 0 && (
                      <tr>
                          <td colSpan={6} className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>No data found</td>
                      </tr>
                    )}
                    {sortedData.map(row => (
                        <React.Fragment key={row.key}>
                            <tr
                                className={`${styles.mainRow} ${darkmode ? styles.dark : ''}`}
                            >
                                <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>
                                    <button className={`${styles.expandButton} ${darkmode ? styles.dark : ''}`}
                                        onClick={() => toggleRowExpansion(row.key)}>
                                        {expandedRows.has(row.key) ? <FaChevronDown /> : <FaChevronRight />}
                                    </button>
                                    {row.symbol}
                                </td>
                                <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>{numberEditor(row.holdingBalance)}</td>
                                <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>{numberEditor(row.measuredBalance)}</td>
                                <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>
                                    <span className={Math.abs(row.percentageDiff) > 1 ? styles.warning : styles.normal}>
                                        {percentageEditor(row.percentageDiff)}
                                    </span>
                                </td>
                                <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>
                                    <span className={Math.abs(row.absoluteDiff) > 0.00000001 ? styles.warning : styles.normal}>
                                        {numberEditor(row.absoluteDiff)}
                                    </span>
                                </td>
                                <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>
                                    <span className={Math.abs(row.usdDiff) > 1 ? styles.warning : styles.normal}>
                                        {numberEditor(row.usdDiff)}
                                    </span>
                                </td>
                            </tr>
                            {expandedRows.has(row.key) && (
                                <>
                                    <ReconciliationDetails data={row.originalData} />
                                    {row.originalData.cryptoCurrencyId && (
                                        <UpdateWalletBalanceRow data={row.originalData} fundId={fundId} />
                                    )}
                                    {row.originalData.currencyIsoCode && (
                                        <UpdateBankBalanceRow data={row.originalData} fundId={fundId} />
                                    )}
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
