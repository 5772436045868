import { BenchmarkCandle } from "../model/benchmarkCandle";

/**
 * Calculates the Pearson correlation coefficient between two series of benchmark values
 * @param fundCandles Array of benchmark candles for the fund
 * @param equityCandles Array of benchmark candles for the equity index
 * @returns Correlation coefficient between -1 and 1, or 0 if insufficient data
 */
export function correlationVsEquities(
    fundCandles: BenchmarkCandle[],
    equityCandles: BenchmarkCandle[]
): number {
    // Need at least 2 periods and same length arrays
    if (fundCandles.length < 2 || fundCandles.length !== equityCandles.length) {
        return 0;
    }

    // Calculate periodic returns for both series
    const fundReturns = [];
    const equityReturns = [];
    
    for (let i = 1; i < fundCandles.length; i++) {
        const fundReturn = (fundCandles[i].normalizedValue - fundCandles[i-1].normalizedValue) 
            / fundCandles[i-1].normalizedValue;
        const equityReturn = (equityCandles[i].normalizedValue - equityCandles[i-1].normalizedValue) 
            / equityCandles[i-1].normalizedValue;
            
        fundReturns.push(fundReturn);
        equityReturns.push(equityReturn);
    }

    // Calculate means
    const fundMean = fundReturns.reduce((sum, val) => sum + val, 0) / fundReturns.length;
    const equityMean = equityReturns.reduce((sum, val) => sum + val, 0) / equityReturns.length;

    // Calculate covariance and variances
    let covariance = 0;
    let fundVariance = 0;
    let equityVariance = 0;

    for (let i = 0; i < fundReturns.length; i++) {
        const fundDiff = fundReturns[i] - fundMean;
        const equityDiff = equityReturns[i] - equityMean;
        
        covariance += fundDiff * equityDiff;
        fundVariance += fundDiff * fundDiff;
        equityVariance += equityDiff * equityDiff;
    }

    covariance /= (fundReturns.length - 1);
    fundVariance /= (fundReturns.length - 1);
    equityVariance /= (fundReturns.length - 1);

    // Calculate correlation coefficient
    const correlation = covariance / (Math.sqrt(fundVariance) * Math.sqrt(equityVariance));

    // Handle edge cases where correlation is slightly outside [-1, 1] due to floating point errors
    return Math.max(-1, Math.min(1, correlation));
}
