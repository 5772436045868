import React, { useState, useEffect, useRef } from 'react'
import { BenchmarkChart } from './Charts/BenchmarkChart';
import ReturnsChart from './Charts/ReturnsChart';
import html2canvas from 'html2canvas';
import BenchmarkTable from './BenchmarkTable';
import BenchmarkTableInputs from './BenchmarkTableInputs';
import BenchmarkIndicatorTable from './BenchmarkIndicatorTable';
import { BitcoinFundChart } from './Charts/BitcoinFundChart';

const BenchmarkDataDisplay = ({ data, benchmarkData, fund, quotes, allReturns, returnsChartData, transformedFundName }) => {
    const [ fulfilledPercentage, setFulfilledPercentage ] = useState(false)
    const [ investment, setInvestment ] = useState(10000000)
    const [ graphDatasets, setGraphDatasets ] = useState([])
    const [ showAdvancedAnalysis, setShowAdvancedAnalysis ] = useState(false)

    // Update the data collection array with the new data
    const handleGraphdataChange = newData => {
        setGraphDatasets(prevData => {
            if (!prevData.some(x => x.fundPercentage === newData.fundPercentage)) {
               return [...prevData, newData]
            } return prevData
        });
    } 
    useEffect(() => { !fulfilledPercentage && setGraphDatasets([]) }, [ fulfilledPercentage ])

    // Set state for quotes data and dynamic percentages handle
    const [ quotesPercentages, setQuotePercentages ] = useState(quotes.data.map(quote => ({ 
        id: quote.id, 
        name: quote.name, 
        symbol: quote.symbol,
        percentage: quote.symbol === "SPY" ? 60 : 40, 
    })))
    
    // Side effect to check if bond percentages sums up 100 percent
    useEffect(() => {
        const percentagesSum = quotesPercentages.reduce((acc, curr) => acc + curr.percentage, 0)
        percentagesSum === 100 ? setFulfilledPercentage(true) : setFulfilledPercentage(false)
    }, [quotesPercentages])
    
    // Function to download containers as image
    const tableRef = useRef()
    const linechartRef = useRef()
    const linechartBitcoinFundRef = useRef()
    const barchartRef = useRef()

    // Function to download containers as SVG
    const handleDownloadSVG = async (containerName, refContainer) => {
        function canvasToSVG(canvas, callback) {
            var svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="' + canvas.width + '" height="' + canvas.height + '">';
                svg += '<image xlink:href="' + canvas.toDataURL("image/png") + '" width="' + canvas.width + '" height="' + canvas.height + '"/>';
                svg += '</svg>';
            
            callback(svg);
        }
    
        // Function to download SVG file
        function downloadSVG(svgContent, fileName) {
            var blob = new Blob([svgContent], { type: "image/svg+xml" });
            var url = URL.createObjectURL(blob);
        
            var a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
    
        const element = refContainer.current;
        const canvas = await html2canvas(element, { backgroundColor: null, scale: 5 });
        
        canvasToSVG(canvas, function(svg) {
            downloadSVG(svg, `${containerName}.svg`);
        });
    };

    // Function to download containers as PNG
    const handleDownloadImage = async (containerName, refContainer) => {
        const element = refContainer.current;
        const canvas = await html2canvas(element, { backgroundColor: null, scale: 5 });
    
        const data = canvas.toDataURL(`${containerName}/png`);
        const link = document.createElement('a');
    
        if (typeof link.download === 'string') {
          link.href = data;
          link.download = `${containerName}.png`;
    
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          window.open(data);
        }
    };

    return (
        <>
            <BenchmarkIndicatorTable
                data={data}
                fund={fund}
                benchmarkData={benchmarkData}
                quotes={quotes}
                transformedFundName={transformedFundName}
                handleDownloadImage={handleDownloadImage}
                handleDownloadSVG={handleDownloadSVG}
                allReturns={allReturns}
            />
            <BenchmarkTableInputs
                data={data} 
                fund={fund} 
                investment={investment}
                setInvestment={setInvestment} 
                quotesPercentages={quotesPercentages} 
                fulfilledPercentage={fulfilledPercentage}    
                setQuotePercentages={setQuotePercentages}
                transformedFundName={transformedFundName}
                showAdvancedAnalysis={showAdvancedAnalysis}
                setShowAdvancedAnalysis={setShowAdvancedAnalysis}
                tableRef={tableRef}
                handleDownloadImage={handleDownloadImage}
                handleDownloadSVG={handleDownloadSVG}
            />
            {   fulfilledPercentage &&
                <>
                    <BenchmarkTable 
                        data={data} 
                        investment={investment} 
                        fund={fund} 
                        quotesPercentages={quotesPercentages}
                        handleGraphdataChange={handleGraphdataChange} 
                        tableRef={tableRef}
                        benchmarkData={benchmarkData}
                        transformedFundName={transformedFundName}
                        showAdvancedAnalysis={showAdvancedAnalysis}
                    />
                    <BenchmarkChart 
                        graphData={graphDatasets} 
                        hookedData={data}
                        fund={fund}
                        transformedFundName={transformedFundName}
                        linechartRef={linechartRef} 
                        handleDownloadImage={handleDownloadImage} 
                        handleDownloadSVG={handleDownloadSVG} 
                    />
                    <ReturnsChart 
                        data={returnsChartData}
                        benchmarkData={benchmarkData}
                        fund={fund}
                        barchartRef={barchartRef}
                        handleDownloadImage={handleDownloadImage} 
                        handleDownloadSVG={handleDownloadSVG}
                        transformedFundName={transformedFundName}
                    />
                    <BitcoinFundChart
                        hookedData={data}
                        returnsChartData={returnsChartData}
                        fund={fund}
                        linechartRef={linechartBitcoinFundRef} 
                        handleDownloadImage={handleDownloadImage}
                        handleDownloadSVG={handleDownloadSVG}
                        transformedFundName={transformedFundName}
                    />
                </>
            }
        </>
    )
}
export default BenchmarkDataDisplay;