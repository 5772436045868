import { BenchmarkCandle } from "../model/benchmarkCandle";

/**
 * Calculates the annualized volatility of negative returns (downside deviation)
 * @param weightedSums Array of benchmark candles with normalized values
 * @returns Annualized downside deviation
 */
export function negativeReturnsVolatility(weightedSums: BenchmarkCandle[]) {
    if (weightedSums.length < 2) {
        return 0;
    }

    // Calculate periodic returns
    const periodicReturns = [];
    for (let i = 1; i < weightedSums.length; i++) {
        const previous = weightedSums[i - 1].normalizedValue;
        const current = weightedSums[i].normalizedValue;
        const periodicReturn = (current - previous) / previous;
        periodicReturns.push(periodicReturn);
    }

    // Filter only negative returns
    const negativeReturns = periodicReturns.filter(return_ => return_ < 0);

    // If no negative returns, return 0
    if (negativeReturns.length === 0) {
        return 0;
    }

    // Calculate mean of negative returns
    const meanReturn = negativeReturns.reduce((sum, return_) => sum + return_, 0) 
        / negativeReturns.length;

    // Calculate variance of negative returns
    const squaredDiffs = negativeReturns.map(return_ => 
        Math.pow(return_ - meanReturn, 2)
    );
    const variance = squaredDiffs.reduce((sum, diff) => sum + diff, 0) 
        /// (negativeReturns.length - 1);  // Using n-1 for sample standard deviation
        / (negativeReturns.length);  // Using population stdev for backwards compatibility

    // Since we have monthly data, we use 12 periods per year
    const periodsPerYear = 12;

    // Annualize volatility
    const downsideDeviation = Math.sqrt(variance * periodsPerYear);
    return downsideDeviation;
}
