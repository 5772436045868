import { BenchmarkCandle } from "./model/benchmarkCandle";
import { calculateValueAtStart } from "./raw/valueAtStart";

export const getValueAtStartResult = (candles: BenchmarkCandle[]) => {
  const initialValue = calculateValueAtStart(candles);
  
  if (initialValue === null) {
    return {
      title: "Initial Value",
      value: "N/A",
      tooltip: "Value of the fund at the start of the period",
    };
  }

  return {
    title: "Initial Value",
    value: `$${initialValue.toLocaleString()}`,
    tooltip: "Value of the fund at the start of the period",
    rawValue: initialValue,
  };
};