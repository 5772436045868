import {
  useBlockchainNetworkCreateOrUpdate,
  useBlockchainNetworkDetails,
} from "../../query-hooks/wallet-query-hook/useWallet";
import { ErrorLoading, Spinner } from "../../Helpers/Helpers";
import { useNavigate, useParams } from "react-router-dom";
import React  from "react";
import styles from "./BlockchainNetworks.module.scss";
import { useAuth } from "../../Hooks/useAuth";
import {
  BlockchainNetworkDetailViewModel,
  SourceIdMapping,
} from "@hodl.nl/hodltradingdesk";
import { Col, Container, Row } from "react-bootstrap";
import {
  useBlockchainSources,
} from "../../query-hooks/crypto-query-hooks/useCryptos";
import { UseQueryResult } from "react-query";
import { AxiosResponse } from "axios";
import {BlockchainNetworksForm} from "./BlockchainNetworksForm";

// TODO: remove once @hodl.nl/hodltradingdesk is updated
export interface BlockchainNetworkExtensionViewModel
  extends BlockchainNetworkDetailViewModel {
  sourceIdMappings: SourceIdMapping[];
  currencySymbol: string;
  isTestNet: boolean;
  autoImportBalances: boolean;
}

/**
 * Details form for a blockchain network
 * @constructor
 */
export function BlockchainNetworksDetails() {
  const navigate = useNavigate();
  const { darkmode } = useAuth();
  const { blockchainNetworkId } = useParams<{ blockchainNetworkId: string }>();
  const details = useBlockchainNetworkDetails(
    blockchainNetworkId,
  ) as UseQueryResult<AxiosResponse<BlockchainNetworkExtensionViewModel, any>>;
  const blockchainSources = useBlockchainSources();

  const updateBlockchainNetworkMutation: any =
    useBlockchainNetworkCreateOrUpdate();

  async function onSubmit(data: any) {
    await updateBlockchainNetworkMutation.mutate({
      id: blockchainNetworkId,
      chain: data,
    });
    navigate(-1);
  }

  if (!!blockchainNetworkId && details.isLoading) {
    return <Spinner />;
  }

  if (!!blockchainNetworkId && details.isError) {
    return <ErrorLoading error={details.error} />;
  }

  if (blockchainSources.isLoading) {
    return <Spinner />;
  }

  return (
    <Container>
      <a
        className={darkmode ? styles.backLinkDark : styles.backLink}
        onClick={() => navigate(-1)}
      >
        Back
      </a>
      <Row>
        <Col xs={5}>
          <h1>{details.data?.data?.name}</h1>
          <p>{details.data?.data?.currencySymbol}</p>
        </Col>
      </Row>
        <BlockchainNetworksForm
          data={blockchainNetworkId == null ? null : details.data?.data!}
          sources={blockchainSources.data!}
          onSubmit={onSubmit}
        ></BlockchainNetworksForm>
    </Container>
  );
}
