import { BenchmarkCandle } from "../model/benchmarkCandle";

export function annualizedReturn(weightedSums: BenchmarkCandle[]) {
    if (weightedSums.length < 2) {
        return 0;
    }

    // Calculate periodic returns
    const periodicReturns = [];
    for (let i = 1; i < weightedSums.length; i++) {
        const previous = weightedSums[i - 1].normalizedValue;
        const current = weightedSums[i].normalizedValue;
        const periodicReturn = (current - previous) / previous;
        periodicReturns.push(periodicReturn);
    }

    // Calculate average periodic return
    const averagePeriodicReturn = periodicReturns.reduce((sum, return_) => sum + return_, 0) 
        / periodicReturns.length;

    // Since we have monthly data, we use 12 periods per year
    const periodsPerYear = 12;

    // Annualize the return
    return (1 + averagePeriodicReturn) ** periodsPerYear - 1;
}
